import React from 'react';
import Modal from 'react-responsive-modal';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import * as session from '../SessionValidator';
import * as enumsBank from '../enums/bank';

export default class ModalBankAccount extends React.Component {

    constructor(props) {

        super(props);

        this.token = session.getToken();

        this.state = {
            bankAccountOpen: false
        };
    }

    async componentDidMount() {

        //se passar o "supplier" como parametro
        if (this.props.supplier) {
            this.setState({ supplier: this.props.supplier });
            this.forceUpdate();
        }
        else {
            this.getSupplier();
        }
    }

    async getSupplier() {

        if (this.props.supplierId && this.props.supplierId > 0) {

            this.setState({ loading: true });

            await fetch(window.$SupplierServiceURL + '/api/supplier/' + this.props.supplierId,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    this.setState({ loading: false });

                    if (res.data) {

                        if (this.props.bankAccount != null) {

                            this.state.bankAccount = this.props.bankAccount;
                        }
                        else if (res.data.bankAccount && res.data.bankAccount.length > 0) {

                            let bankAccount = res.data.bankAccount.find(x => { return x.isMaster; });
                            this.state.bankAccount = bankAccount ? bankAccount : res.data.bankAccount[0];

                            this.props.handleChangeBankAccount(this.state.bankAccount);
                        }
                        else {
                            this.state.bankAccount = {
                                name: '',
                                BankNumber: '',
                                agencyNumber: '',
                                accountNumber: '',
                                notes: '',
                                isActive: true
                            };
                        }
                    }

                    this.setState({ supplier: res.data });
                    this.forceUpdate();
                });

        } else {

            let bankAccount = {
                name: '',
                BankNumber: '',
                agencyNumber: '',
                accountNumber: '',
                notes: '',
                isActive: true
            };

            this.setState({
                bankAccount: bankAccount,
                supplier: {}
            });

            this.forceUpdate();
        }
    }

    handleEditBankAccount() {

        this.state.bankAccountOpen = true;

        this.forceUpdate();
    }

    handleReturnBankAccount(bankAccount) {

        this.state.bankAccountOpen = false;

        this.forceUpdate();
    }

    handleChangeBankAccount(bankAccount) {

        this.props.handleChangeBankAccount(bankAccount);

        this.state.bankAccount = bankAccount;
        this.forceUpdate();

        this.handleReturnBankAccount(bankAccount);
    }

    render() {

        var fetched = this.state != null && this.state.supplier != null && this.state.bankAccount != null;

        if (fetched) {

            return (
                <div>

                    <div className="col-md-12">
                        <div className="m-b-5" style={{ padding: '15px', border: '1px solid #ddd' }}>
                            <div>
                                <h4>{translate('transaction.transactionBankAccountTitle')}</h4>
                            </div>

                            <p>
                                <b>{translate('supplier.supplierSetupAccountBankNumber')}: </b>{this.state.bankAccount.bankNumber > 0 ? enumsBank.getBankNumber().find(x => { return x.index === this.state.bankAccount.bankNumber; }).text : ''}
                                <b> {translate('supplier.supplierSetupAccountAgencyNumber')}: </b>{this.state.bankAccount.agencyNumber}
                                <b> {translate('supplier.supplierSetupAccountNumber')}: </b>{this.state.bankAccount.accountNumber}</p>

                            {
                                (session.isAdmin() || session.isOperator() || session.isSupplier()) &&
                                (this.props.transactionStatus == 0 || this.props.transactionStatus == 7) &&
                                <button className="btn bg-deep-purple waves-effect" type="button" disabled={this.props.loading} onClick={() => { this.handleEditBankAccount(); }}>
                                    <i className="material-icons">&#xe8d5;</i>
                                    <span>{translate('transaction.transactionChangeAccount')}</span>
                                </button>
                            }

                        </div>
                    </div>

                    {/* Bank Accounts */}
                    <Modal
                        open={this.state.bankAccountOpen}
                        closeOnEsc={false}
                        onClose={() => { }}
                        onEntered={() => { this.forceUpdate(); }}
                        closeIconSize={0}
                        center={true}
                        styles={{ modal: { maxWidth: '80%' } }}
                    >
                        <div>
                            <div style={{ width: 9999 }}></div>

                            <div>
                                <h4>{translate('supplier.supplierSetupAccountTitle')}</h4>
                                <hr />
                            </div>

                            <div>

                                <ReactTable
                                    data={this.state.supplier.bankAccount}
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: translate('supplier.supplierSetupAccountName'),
                                                    id: "name",
                                                    accessor: data => { return <span title={data.name}> {data.name} </span> }
                                                },
                                                {
                                                    Header: translate('supplier.supplierSetupAccountBankNumber'),
                                                    id: "bankNumber",
                                                    accessor: data => { return <span title={enumsBank.getBankNumber().find(x => { return x.index === data.bankNumber; }).text}> {enumsBank.getBankNumber().find(x => { return x.index === data.bankNumber; }).text} </span> }
                                                },
                                                {
                                                    Header: translate('supplier.supplierSetupAccountAgencyNumber'),
                                                    accessor: "agencyNumber",
                                                    maxWidth: 100
                                                },
                                                {
                                                    Header: translate('supplier.supplierSetupAccountNumber'),
                                                    accessor: "accountNumber",
                                                    maxWidth: 100
                                                },
                                                {
                                                    Header: translate('supplier.supplierSetupAccountNotes'),
                                                    id: "notes",
                                                    accessor: data => { return <span title={data.notes}> {data.notes} </span> }
                                                },
                                                {
                                                    Header: translate('supplier.supplierSetupAccountIsMaster'),
                                                    Cell: row => {
                                                        return <div className="align-center">

                                                            {row.original.isMaster &&
                                                                <i className="material-icons color-success">&#xe86c;</i>
                                                            }
                                                            {!row.original.isMaster &&
                                                                <i className="material-icons color-danger">&#xe5c9;</i>
                                                            }

                                                        </div>
                                                    },
                                                    maxWidth: 100
                                                },
                                                {
                                                    Header: translate('supplier.supplierSetupAccountIsActive'),
                                                    Cell: row => {
                                                        return <div className="align-center">

                                                            {row.original.isActive &&
                                                                <i className="material-icons color-success">&#xe86c;</i>
                                                            }
                                                            {!row.original.isActive &&
                                                                <i className="material-icons color-danger">&#xe5c9;</i>
                                                            }

                                                        </div>
                                                    },
                                                    maxWidth: 100
                                                }
                                            ]
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                    loading={this.props.loading}
                                    previousText={translate('forms.previousText')}
                                    nextText={translate('forms.nextText')}
                                    noDataText={translate('forms.noDataText')}
                                    pageText={translate('forms.pageText')}
                                    ofText={translate('forms.ofText')}
                                    rowsText={translate('forms.rowsText')}
                                    getTrProps={(state, row, column) => {
                                        return {
                                            onClick: () => { if (!this.props.loading) this.handleChangeBankAccount(row.original); },
                                            style: { cursor: 'pointer' }
                                        }
                                    }}
                                />

                            </div>

                            <div className="row m-t-20">
                                <div className="col-xs-12 align-right">

                                    <button type="button" className="btn btn-default waves-effect" disabled={this.props.loading} onClick={() => { this.handleReturnBankAccount(); }}>
                                        <i className="material-icons">&#xe5c4;</i>
                                        <span>{translate('forms.buttonReturn')}</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
            )
        } else {

            return (
                <div className="col-md-12">
                    <div className="m-b-5" style={{ padding: '15px', border: '1px solid #ddd' }}>
                        <div className="preloader pl-size-lg align-center">
                            <div className="spinner-layer">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>

                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
