import React from 'react';
import { submitReportDailyLog } from "../../pages/helper.js";
import ReactTable from "react-table";
import { translate } from 'react-internationalization';
import * as session from '../SessionValidator';
import SimpleReactValidator from 'simple-react-validator';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import RCSelect from '../select';
import { cnpj } from '../cpf-cnpj-validator';
import { cpf } from '../cpf-cnpj-validator';
import { cpf_cnpj_Mask } from '../mask';
import $ from 'jquery';

const moment = window.Moment;

export default class AddressCustomerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.setValidators();
        this.token = session.getToken();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTypingEmail = this.handleTypingEmail.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
        this.resetAddress = this.resetAddress.bind(this);
        this.handlechangeMask = this.handlechangeMask.bind(this)

        this.state = {


            currentAddress: {

                rowIndex: -1,
                address: {
                    id: 0,
                    name: '',
                    legalName: '',
                    cnpj: '',
                    stateRegistration: '',
                    phoneNumber: '',
                    email: '',
                    countryId: 403,
                    stateId: 0,
                    city: '',
                    district: '',
                    street: '',
                    number: '',
                    complement: '',
                    zipCode: '',
                    isMaster: true,
                    stateName: '',
                    countryName: ''
                }
            },

            countries: null,
            addressStates: null,

            addressList: [],
            loading: false,

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },
        };
    }

    async componentDidUpdate() {

        let _this = this;

        $('.js-customer-address-change').each(function () { _this.setValue(this); });
        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });
    }

    async componentDidMount() {

        let _this = this;

        $('body').on('change', '.js-customer-address-change', function (event) { _this.handleChange(event); });

        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });

        fetch(window.$CustomerServiceURL + '/api/Country/List',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ countries: res.data });

                let countrySelected = this.state.countries.find(item => { return item.id === this.state.currentAddress.address.countryId; });

                if (countrySelected)
                    this.state.currentAddress.address.countryName = countrySelected.name;

                this.loadStates('addressStates', 'addressStatesLoading', 403);// 403 = Brasil

                this.forceUpdate();
            });

        if (this.props.customerId) {
            fetch(window.$CustomerServiceURL + '/api/customer/ListCustomerAddress/' + this.props.customerId,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                    if (res.status === 404) {
                        res = [];

                        return res;

                    }

                })
                .then(res => {

                    res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                    this.setState({ addressList: res.data });
                    this.forceUpdate();
                });
        } else {

            let currentAddress = {

                rowIndex: -1,
                address: {
                    id: 0,
                    name: '',
                    legalName: '',
                    cnpj: '',
                    stateRegistration: '',
                    phoneNumber: '',
                    email: '',
                    countryId: 403,
                    stateId: 0,
                    city: '',
                    district: '',
                    street: '',
                    number: '',
                    complement: '',
                    zipCode: '',
                    isMaster: true,
                    stateName: ''
                }
            };

            let addressList = [];

            this.setState({
                currentAddress: currentAddress,
                addressList: addressList
            });
            this.forceUpdate();
        }
    }


    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        if (event.target.id === "cmbAddressCountry") {

            this.state.addressStates = null;

            if (event.target.value > 0) this.loadStates('addressStates', 'addressStatesLoading', event.target.value);

            let countrySelected = this.state.countries.find(item => { return item.id === event.target.value; });

            if (countrySelected)
                this.state.currentAddress.address.countryName = countrySelected.name;
        }

        if (event.target.id === "cmbAddressState") {

            let stateSelected = this.state.addressStates.find(item => { return item.id === event.target.value; });

            if (stateSelected)
                this.state.currentAddress.address.stateName = stateSelected.name;
        }

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.forceUpdate();

        this.setState(this.state);
    }

    handlechangeMask(e) {
        e.target.value = cpf_cnpj_Mask(e.target.value);
    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    handleTypingEmail(e) {
        e.target.value = e.target.value.toLowerCase()
    }

    resetAddress() {

        this.state.currentAddress = {

            rowIndex: -1,
            address: {
                id: 0,
                name: '',
                legalName: '',
                cnpj: '',
                stateRegistration: '',
                phoneNumber: '',
                email: '',
                countryId: 403,
                stateId: null,
                city: '',
                district: '',
                street: '',
                number: '',
                complement: '',
                zipCode: '',
                isMaster: true
            }
        };

        if (this.addressValidator !== undefined) {
            this.addressValidator.hideMessages();
        }

        this.setState(this.state);
    }

    saveAddress() {

        if (!this.addressValidator.allValid()) {

            this.addressValidator.showMessages();
            this.forceUpdate();
            return;
        }

        this.handleSubmit();
    }

    editAddress(rowIndex) {

        let reportDailyLog = this.state.reportDailyLog;

        this.state.currentAddress.address = JSON.parse(JSON.stringify(this.state.addressList[rowIndex]));
        this.state.currentAddress.rowIndex = rowIndex;

        if (this.state.addressList[rowIndex].countryId > 0) this.loadStates('addressStates', 'addressStatesLoading', this.state.addressList[rowIndex].countryId);

        reportDailyLog.objectBefore = JSON.stringify(this.state.currentAddress.address);

        this.setState(this.state);
        this.forceUpdate();
    }

    async loadStates(states, statesLoading, countryId) {

        this.state[statesLoading] = true;
        this.setState(this.state);

        fetch(window.$CustomerServiceURL + '/api/State/List?countryId=' + countryId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                this.state[states] = res.data;
                this.state[statesLoading] = false;

                this.setState(this.state);

                this.forceUpdate();
            });
    }

    handleSubmit() {

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ loading: true });

        var method = this.state.currentAddress.address.id > 0 ? 'UpdateAddress' : 'AddAddress';

        this.state.currentAddress.address.customerId = this.props.customerId;

        var status = 0;

        fetch(window.$CustomerServiceURL + '/api/customer/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(this.state.currentAddress.address),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                status = res.status;

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (status) {

                    case 200:
                        var address = JSON.parse(JSON.stringify(this.state.currentAddress.address));

                        reportDailyLog.action = 3; // updateCustomer
                        reportDailyLog.objectAfter = JSON.stringify(address);
                        submitReportDailyLog(reportDailyLog);

                        address.id = res.data.id;

                        if (address.isMaster) {

                            //desabilita todos
                            for (var i = 0; i < this.state.addressList.length; i++) {

                                this.state.addressList[i].isMaster = false;
                            }
                        }

                        if (this.state.currentAddress.rowIndex < 0) {

                            this.state.addressList.push(address);

                        } else {

                            this.state.addressList[this.state.currentAddress.rowIndex] = address;
                        }

                        this.resetAddress();

                        this.setState({ loading: false });

                        this.forceUpdate();
                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });
    }

    deleteAddress(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        let address = JSON.parse(JSON.stringify(this.state.addressList[rowIndex]));

                        let addressId = address.id;

                        this.handleDeleteAddress(addressId, rowIndex);
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerAddressDeleteTitle'),
            title: translate('customer.customerAddressDeleteQuestion')
        });

    }

    handleDeleteAddress(addressId, rowIndex) {

        let reportDailyLog = this.state.reportDailyLog;
        var status = 0;

        this.setState({ loading: true });


        fetch(window.$CustomerServiceURL + '/api/customer/DeleteAddress/' + addressId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                status = res.status;
                this.setState({ loading: false });

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (status) {

                    case 200:
                        let address = JSON.parse(JSON.stringify(this.state.addressList[rowIndex]));

                        var items = this.state.addressList;
                        items.splice(rowIndex, 1);
                        this.state.addressList = items;

                        reportDailyLog.action = 3; //updateCustomer
                        reportDailyLog.objectAfter = JSON.stringify(address);
                        submitReportDailyLog(reportDailyLog);

                        this.forceUpdate();

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });

    }

    setValidators() {

        this.addressValidator = new SimpleReactValidator({

            state: {

                rule: value => {

                    return !this.state.addressStates || this.state.addressStates.length <= 0 || parseInt(value) > 0;
                }
            },

            cnpj: {

                rule: (value) => {

                    return cnpj.isValid(value);
                }
            },

            cnpjAdded: {

                rule: (value) => {

                    var item = this.state.addressList ? this.state.addressList.find(item => { return item.cnpj.replace(".", "").replace(".", "").replace("-", "").replace("/", "") === value.replace(".", "").replace(".", "").replace("-", "").replace("/", ""); }) : null;

                    return !item || (item && this.state.currentAddress.rowIndex >= 0);
                }
            },

            email: {

                rule: function (val) {

                    if (val === '')
                        return true;

                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val.trim()))
                        return false;

                    return true;
                }
            }

        });
    }

    render() {
        return (
            <div>
                {
                    session.auth([{ type: "Customer", value: "Save" }]) &&
                    <div>
                        <div>
                            <div className="m-b-40">
                                <small>{translate('customer.customerAddressTitleDescription')}</small>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-10">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtAddressName">{translate('customer.customerAddressName')}</label>

                                    <div className="form-line">
                                        <input id="txtAddressName" name="currentAddress.address.name" className="form-control js-customer-address-change" placeholder={translate('customer.customerAddressNamePlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.name', this.state.currentAddress.address.name, 'required', false, { default: translate('customer.customerAddressNameRequired') })}
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="m-t-30">
                                    <input id="chkIsMaster" name="currentAddress.address.isMaster" type="checkbox" checked={this.state.currentAddress.address.isMaster} onChange={this.handleChange} />
                                    <label htmlFor="chkIsMaster">{translate('customer.customerAddressIsMaster')}</label>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">

                            <div className="col-md-9">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtAddressLegalName">{translate('customer.customerLegalName')}</label>

                                    <div className="form-line">
                                        <input id="txtAddressLegalName" name="currentAddress.address.legalName" className="form-control js-customer-address-change" autoComplete="off" maxLength='100' placeholder={translate('customer.customerLegalNamePlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.legalName', this.state.currentAddress.address.legalName, 'required', false, { default: translate('customer.customerLegalNameRequired') })}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="input-group">
                                    <label htmlFor="txtAddressCnpj" className="validationRequired">{translate('customer.customerCNPJ')}</label>

                                    <div className="form-line">
                                        <input id="txtAddressCnpj" name="currentAddress.address.cnpj" maxLength='19' onChange={this.handlechangeMask} className="form-control js-customer-address-change" autoComplete="off" placeholder={translate('customer.customerCNPJPlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.cnpj', this.state.currentAddress.address.cnpj, 'required|cnpj|cnpjAdded', false, { default: translate('customer.customerCNPJRequired'), cnpj: translate('customer.customerCNPJInvalid'), cnpjAdded: translate('customer.customerCnpjAdded') })}
                                </div>
                            </div>

                        </div>

                        <div className="row clearfix">
                            <div className="col-md-4">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtStreetName">{translate('customer.customerAddressStreet')}</label>

                                    <div className="form-line">
                                        <input id="txtStreetName" name="currentAddress.address.street" className="form-control js-customer-address-change" placeholder={translate('customer.customerAddressStreetPlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.street', this.state.currentAddress.address.street, 'required', false, { default: translate('customer.customerAddressStreetRequired') })}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label htmlFor="txtStreetNumber">{translate('customer.customerAddressNumber')}</label>

                                    <div className="form-line">
                                        <input id="txtStreetNumber" name="currentAddress.address.number" className="form-control js-customer-address-change js-integer" placeholder={translate('customer.customerAddressNumberPlaceHolder')} type="text" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label htmlFor="txtDistrict">{translate('customer.customerAddressDistrict')}</label>

                                    <div className="form-line">
                                        <input id="txtDistrict" name="currentAddress.address.district" className="form-control js-customer-address-change" placeholder={translate('customer.customerAddressDistrictPlaceHolder')} type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-4">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtZipCode">{translate('customer.customerAddressZipCode')}</label>

                                    <div className="form-line">
                                        <input id="txtZipCode" name="currentAddress.address.zipCode" className="form-control js-customer-address-change js-zipcode" placeholder={translate('customer.customerAddressZipCodePlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.zipCode', this.state.currentAddress.address.zipCode, 'required', false, { default: translate('customer.customerAddressZipCodeRequired') })}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtCity">{translate('customer.customerAddressCity')}</label>

                                    <div className="form-line">
                                        <input id="txtCity" name="currentAddress.address.city" className="form-control js-customer-address-change" placeholder={translate('customer.customerAddressCityPlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.city', this.state.currentAddress.address.city, 'required', false, { default: translate('customer.customerAddressCityRequired') })}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label htmlFor="txtComplement">{translate('customer.customerAddressComplement')}</label>

                                    <div className="form-line">
                                        <input id="txtComplement" name="currentAddress.address.complement" className="form-control js-customer-address-change" placeholder={translate('customer.customerAddressComplementPlaceHolder')} type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-6">
                                <div className="m-b-20">
                                    <label className="validationRequired" htmlFor="cmbAddressCountry">{translate('customer.customerAddressCountry')}</label>

                                    <RCSelect
                                        id="cmbAddressCountry"
                                        name="currentAddress.address.countryId"
                                        hasEmptyOption={true}
                                        isInt={true}
                                        options={this.state.countries}
                                        optionValue="id"
                                        optionLabel="name"
                                        placeholder={translate('customer.customerAddressCountryPlaceHolder')}
                                        onChange={event => { this.handleChange(event); }}
                                        value={this.state.currentAddress.address.countryId}
                                    />

                                    {this.addressValidator.message('currentAddress.address.countryId', this.state.currentAddress.address.countryId, 'gt: 0', false, { default: translate('customer.customerAddressCountryRequired') })}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="m-b-20">
                                    <label htmlFor="cmbAddressState" className={this.state.addressStates && this.state.addressStates.length > 0 ? 'validationRequired' : ''}>
                                        {translate('customer.customerAddressState')}
                                    </label>

                                    <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.addressStatesLoading ? 'inline-block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <RCSelect
                                        id="cmbAddressState"
                                        name="currentAddress.address.stateId"
                                        hasEmptyOption={true}
                                        isInt={true}
                                        options={this.state.addressStates != null ? this.state.addressStates : this.state.states}
                                        optionValue="id"
                                        optionLabel="displayName"
                                        placeholder={translate('customer.customerAddressStatePlaceHolder')}
                                        onChange={event => { this.handleChange(event); }}
                                        value={this.state.currentAddress.address.stateId}
                                    />

                                    {this.addressValidator.message('cmbAddressState', this.state.currentAddress.address.stateId, 'gt: 0', false, { default: translate('customer.customerAddressStateRequired') })}
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">

                            <div className="col-md-3">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtAddressPhoneNumber">{translate('customer.customerContactPhoneNumber')}</label>

                                    <div className="form-line">
                                        <input id="txtAddressPhoneNumber" name="currentAddress.address.phoneNumber" maxLength="15" className="form-control js-customer-address-change js-phone-number" placeholder={translate('customer.customerContactPhoneNumberPlaceHolder')} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.phoneNumber', this.state.currentAddress.address.phoneNumber, 'required', false, { default: translate('customer.customerContactPhoneNumberRequired') })}
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="input-group">
                                    <label htmlFor="txtAddressEmail">{translate('customer.customerEmail')}</label>

                                    <div className="form-line">
                                        <input id="txtAddressEmail" className="form-control js-customer-address-change" name="currentAddress.address.email" placeholder={translate('customer.customerEmailPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                    </div>

                                    {this.addressValidator.message('currentAddress.address.email', this.state.currentAddress.address.email, 'email', false, { default: translate('customer.customerEmailInvalid') })}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label htmlFor="txtStateRegistration"> {translate('customer.customerStateRegistration')} </label>

                                    <div className="form-line">
                                        <input id="txtStateRegistration" name="currentAddress.address.stateRegistration" className="form-control js-customer-address-change" autoComplete="off" placeholder={translate('customer.customerStateRegistrationPlaceHolder')} type="text" />
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row clearfix">
                            <div className="col-md-12">
                                <button type="button" className="btn m-r-10" onClick={this.resetAddress} style={{ display: this.state.currentAddress.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                    <i className="material-icons">&#xe5c4;</i>
                                    <span>{translate('forms.buttonCancel')}</span>
                                </button>

                                <button type="button" className="btn btn-primary" onClick={this.saveAddress}>
                                    <i className="material-icons">&#xe145;</i>
                                    <span>{translate(this.state.currentAddress.rowIndex < 0 ? 'customer.customerAddressesAddNew' : 'customer.customerAddressesUpdate')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                }

                <ReactTable
                    data={this.state.addressList}
                    columns={[
                        {
                            columns: [
                                {
                                    Header: translate('customer.customerAddressName'),
                                    accessor: "name",
                                },
                                {
                                    Header: translate('customer.customerCNPJ'),
                                    accessor: "cnpj",
                                },
                                {
                                    Header: translate('customer.customerAddressState'),
                                    accessor: "stateName",

                                },
                                {
                                    Header: translate('customer.customerAddressCity'),
                                    accessor: "city",

                                },
                                {
                                    Header: translate('customer.customerAddressDistrict'),
                                    accessor: "district",

                                },
                                {
                                    Header: translate('customer.customerAddressIsMaster'),
                                    Cell: row => {
                                        return <div className="align-center">

                                            {row.original.isMaster &&
                                                <i className="material-icons color-success">&#xe86c;</i>
                                            }
                                            {!row.original.isMaster &&
                                                <i className="material-icons color-danger">&#xe5c9;</i>
                                            }

                                        </div>
                                    },
                                    maxWidth: 100
                                },
                                session.auth([{ type: "Customer", value: "Save" }]) &&
                                {
                                    Cell: row => (
                                        <div className="align-center">
                                            <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" disabled={this.state.currentAddress.rowIndex >= 0} onClick={() => { this.deleteAddress(row.index) }}>
                                                <i className="material-icons">&#xe872;</i>
                                            </button>

                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" disabled={this.state.currentAddress.rowIndex >= 0} onClick={() => { this.editAddress(row.index) }}>
                                                <i className="material-icons">&#xe3c9;</i>
                                            </button>
                                        </div>
                                    ),
                                    maxWidth: 150
                                }
                            ]
                        }
                    ]}
                    SubComponent={row => {
                        return <div className="m-l-30 m-r-30 m-t-30">
                            <div className="row">
                                <div className="col-md-3">
                                    <p>
                                        {translate('customer.customerAddressCountry')}:
                                        <b> {row.original.countryName}</b>
                                    </p>
                                </div>

                                <div className="col-md-4">
                                    <p>
                                        {translate('customer.customerLegalName')}:
                                        <b> {row.original.legalName}</b>
                                    </p>
                                </div>

                                <div className="col-md-5">
                                    <p>
                                        {translate('customer.customerAddressStreet')}:
                                        <b> {row.original.street}</b>
                                    </p>
                                </div>

                                <div className="col-md-3">
                                    <p>
                                        {translate('customer.customerAddressZipCode')}:
                                        <b> {row.original.zipCode}</b>
                                    </p>

                                </div>
                                <div className="col-md-4">
                                    <p>
                                        {translate('customer.customerAddressNumber')}:
                                        <b> {row.original.number}</b>
                                    </p>
                                </div>

                                <div className="col-md-5">
                                    <p>
                                        {translate('customer.customerAddressComplement')}:
                                        <b> {row.original.complement}</b>
                                    </p>
                                </div>

                                <div className="col-md-3">
                                    <p>
                                        {translate('customer.customerContactPhoneNumber')}:
                                        <b> {row.original.phoneNumber}</b>
                                    </p>
                                </div>

                                <div className="col-md-4">
                                    <p>
                                        {translate('customer.customerEmail')}:
                                        <b> {row.original.email}</b>
                                    </p>
                                </div>

                                <div className="col-md-5">
                                    <p>
                                        {translate('customer.customerStateRegistration')}:
                                        <b> {row.original.stateRegistration}</b>
                                    </p>
                                </div>

                            </div>
                        </div>
                    }}
                    defaultPageSize={10}
                    className="-striped -highlight m-b-40"
                    previousText={translate('forms.previousText')}
                    nextText={translate('forms.nextText')}
                    noDataText={translate('forms.noDataText')}
                    pageText={translate('forms.pageText')}
                    ofText={translate('forms.ofText')}
                    rowsText={translate('forms.rowsText')}
                />
            </div>
        )
    }
}