import React from 'react';
import { submitReportDailyLog } from './helper';

const moment = window.Moment;

class LogoutPage extends React.Component {
    displayName = LogoutPage.name
    constructor(props) {

        super(props);

        this.state = {
            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            }
        }

        let reportDailyLog = this.state.reportDailyLog;

        reportDailyLog.accountId = localStorage.getItem('userId');
        reportDailyLog.action = 1;
        reportDailyLog.objectBefore = localStorage.getItem('email');
        reportDailyLog.startDate = moment().format();

        submitReportDailyLog(reportDailyLog);

        // expires the token passing a past date 
        localStorage.removeItem('authp');

        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        localStorage.removeItem('name');

        localStorage.removeItem('customerId');
        localStorage.removeItem('supplierId');
        localStorage.removeItem('bankId');
        
        localStorage.removeItem('ia');// is admin
        localStorage.removeItem('io');// is operator
        localStorage.removeItem('ut');// isUserTerm

        localStorage.removeItem('t');// type user

        localStorage.removeItem('r');// roles

        localStorage.removeItem('sessionTimeOut');
        localStorage.removeItem('clickHour');

        window.location.href = '/login';
    }

    render() {

        return (<div></div>)
    }
}
export default LogoutPage
