import { translate } from 'react-internationalization';

export function getStatus() {

    let list = [

        { text: translate('optIn.optInActive'), index: 0 },
        { text: translate('optIn.optInInactive'), index: 1 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getPaymentStatus() {

    let list = [

        { text: translate('enums.optInPaymentArrangementType.credit'), index: 0 },
        { text: translate('enums.optInPaymentArrangementType.debit'), index: 1 },
        { text: translate('enums.optInPaymentArrangementType.prepaid'), index: 2 },
        { text: translate('enums.optInPaymentArrangementType.ticket'), index: 3 },
        { text: translate('enums.optInPaymentArrangementType.purchase'), index: 4 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}