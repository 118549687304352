import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { translate } from 'react-internationalization';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalSendEmailRemember from '../components/document/modal-send-emailRemember';
import * as enumsSig from '../components/enums/signatoryType';
import * as session from '../components/SessionValidator';
import ModalContract from '../components/transaction/modal-contract';

class documentViewPage extends React.Component {

    constructor(props) {

        super(props);

        this.token = session.getToken();

        this.setBinds();

        this.state = {

            fileHash: this.props.match.params.fileHash,

            transaction: null,

            scrollEnd: null,
            loading: true,

            openEmail: false,
            signer: {},
            signerType: 0,

            showContract: false,
            document: null,

            signatoryList: []
        };
    }

    setBinds() {

        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    async componentDidMount() {

        let signatoryList = [];

        fetch(window.$DocumentServiceURL + '/api/Document/' + this.state.fileHash + "?withFile=true",
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (res.code) {

                    case 200:

                        if (res.data && res.data.signatory)
                            signatoryList = res.data.signatory.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        this.setState({
                            document: res.data,
                            signatoryList: signatoryList,
                            loading: false
                        });
                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });
    }

    downloadSignedDocument() {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonOk'),
                    onClick: () => { }
                }
            ],
            title: translate('transaction.transactionDownloadDocumentTitle'),
            message: translate('transaction.transactionDownloadDocumentMessage')
        });

    }

    handleChangeShowContract() {

        this.state.showContract = true;
        this.forceUpdate();
    }

    handleChangeOpenEmail(signer, signerType) {

        this.state.openEmail = true;
        this.state.signer = signer;
        this.state.signerType = signerType;
        this.forceUpdate();
    }

    render() {

        var fetched = this.state != null && !this.state.loading;

        if (fetched) {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    {translate('document.documentName')}
                                </h2>
                            </div>

                            <div className="body">
                                <div className="tab-content">
                                    <div id="transactionPanel" className="tab-pane fade in active" role="tabpanel">
                                        <div className="m-t-10">
                                            <div className="row">

                                                <div className='col-md-5'>
                                                    <div className="m-b-5">
                                                        <label htmlFor="customerName">{translate('documents.documentFileName')} </label>
                                                    </div>

                                                    <div className="text">{this.state.document.fileName}</div>
                                                </div>

                                                <div className="col-md-3">

                                                    <div className="m-b-5">
                                                        <label> {translate('documents.documentCreationDate')} </label>
                                                    </div>

                                                    <div className="text">{window.Moment(this.state.document.createDate, 'YYYY-MM-DD h:mm:ss a').format('DD-MM-YYYY h:mm:ss a')}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="m-b-10" style={{ padding: '15px', border: '1px solid #ddd' }}>
                                                    <div className="m-b-10">
                                                        <h4>{translate('signatory.signatoryList')}</h4>

                                                    </div>

                                                    <div className="row">
                                                        <table className="table">
                                                            <tbody>
                                                                {this.state.signatoryList.map((signatory) => (
                                                                    <tr>
                                                                        <th style={{ width: '20px' }}>

                                                                            {signatory.status &&
                                                                                <i className={signatory.status ? "material-icons col-green" : "material-icons col-orange"}>
                                                                                    &#xe86c;
                                                                                </i>
                                                                            }
                                                                            {!signatory.status &&
                                                                                <i className={signatory.status ? "material-icons col-green" : "material-icons col-orange"}>
                                                                                    &#xe924;
                                                                                </i>
                                                                            }
                                                                        </th>

                                                                        <td>
                                                                            <span style={{ display: 'flex' }}>{signatory.name}</span>
                                                                            <span style={{ color: '#909098' }}>
                                                                                {enumsSig.getTypes().find(x => { return x.index === parseInt(signatory.type); }).text}
                                                                            </span>
                                                                        </td>

                                                                        <td style={{ width: '20px' }}>
                                                                            {!signatory.status &&
                                                                                <button title={signatory.openEmailDate ? "Abertura de e-mail " + window.Moment(signatory.openEmailDate, 'YYYY-MM-DD h:mm:ss a').format('DD-MM-YYYY h:mm:ss a') : ""} type="button" onClick={() => { this.handleChangeOpenEmail(signatory, signatory.type) }} className="btn btn-default btn-circle waves-effect waves-circle waves-float">

                                                                                    {signatory.openEmail &&
                                                                                        <i className="material-icons">&#xe151;</i>
                                                                                    }
                                                                                    {!signatory.openEmail &&
                                                                                        <i className="material-icons">&#xe0be;</i>
                                                                                    }

                                                                                </button>
                                                                            }
                                                                        </td>
                                                                        <td style={{ width: '110px' }}>
                                                                            <span style={{ display: 'flex' }}>{signatory.signatureDate ? window.Moment(signatory.signatureDate, 'YYYY-MM-DD hh:mm:ss a').format('DD-MM-YYYY') : ""}</span>
                                                                            <span>{signatory.signatureDate ? window.Moment(signatory.signatureDate, 'YYYY-MM-DD hh:mm:ss a').format('hh:mm:ss a') : ""}</span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <button className="btn bg-deep-purple waves-effect m-r-10" type="button" onClick={() => { this.handleChangeShowContract() }}>
                                                        <i className="material-icons">&#xe873;</i>
                                                        <span>{translate('forms.toViewDocument')}</span>
                                                    </button>

                                                    <div className="btn-group" role="group">

                                                        <a style={{ display: this.state.document.documentType == 1 ? 'block' : 'none' }} href={window.$DocumentServiceURL + "/api/Document/Download/" + this.state.document.fileHash + "/" + this.state.document.fileHashEncrypted} className="btn bg-deep-purple waves-effect">
                                                            <i className="material-icons">&#xe2c0;</i>
                                                            <span>{translate('forms.downloadContract')}</span>
                                                        </a>

                                                        <div style={{ display: this.state.document.documentType == 2 ? 'block' : 'none' }}>
                                                            <button id="btnDownload" type="button" className="btn bg-deep-purple waves-effect dropdown-toggle" data-toggle="dropdown">
                                                                <i className="material-icons">&#xe2c0;</i>
                                                                <span>{translate('forms.downloadContract')}</span>
                                                                <span className="caret"></span>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="btnDownload">
                                                                <li>
                                                                    <a href={window.$DocumentServiceURL + "/api/Document/Download/" + this.state.document.fileHash + "/" + this.state.document.fileHashEncrypted} className="waves-effect waves-block">{translate('forms.downloadOriginalDocument')}</a>
                                                                </li>
                                                                <li>
                                                                    {this.state.document.status === 0 &&
                                                                        <a href="javascript:void(0);" onClick={() => { this.downloadSignedDocument() }} className="waves-effect waves-block">{translate('forms.downloadSignedDocument')}</a>
                                                                    }

                                                                    {this.state.document.status > 0 &&
                                                                        <a href={window.$DocumentServiceURL + "/api/Document/Download/" + this.state.document.fileHashSigned + "/" + this.state.document.fileHashEncryptedSigned} className="waves-effect waves-block">{translate('forms.downloadSignedDocument')}</a>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row clearfix" ref={(el) => { this.state.scrollEnd = el; }}>
                                    <div className="col-md-11 formActions">
                                        <Link to="/documents" className="btn btn-default waves-effect">
                                            <i className="material-icons">&#xe5c4;</i>
                                            <span>{translate('forms.buttonReturn')}</span>
                                        </Link>
                                    </div>
                                </div>

                                <ModalSendEmailRemember
                                    signer={this.state.signer}
                                    signerType={this.state.signerType}
                                    fileHash={this.state.fileHash}
                                    openEmail={this.state.openEmail}
                                    loading={this.state.loading}
                                    closeEmail={() => {
                                        this.setState({ openEmail: false });
                                        this.setState({ loading: false });
                                        this.forceUpdate();
                                    }}
                                />

                                {!this.state.loading &&
                                    <ModalContract
                                        fileHash={this.state.fileHash}
                                        document={this.state.document}
                                        open={this.state.showContract}
                                        close={() => {
                                            this.setState({ showContract: false });
                                            this.forceUpdate();
                                        }}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {

    function handleClick() {

        tabToShow.trigger('click');
        closeToast();
    }

    return (

        <div onClick={handleClick}>
            {message}
        </div>
    );
}

export default documentViewPage;