import React from 'react';
import ReactTable from "react-table";
import { translate } from 'react-internationalization';
import * as session from '../SessionValidator';
import { toast } from 'react-toastify';
import * as enums from '../enums/optIn';

const moment = window.Moment;

export default class PaymentArrangementComponent extends React.Component {

    constructor(props) {
        super(props);

        this.token = session.getToken();

        this.state = {

            selected: {},
            selectAll: 0,
            selectedPayments: this.props.selectedPayments,
            paymentArrangements: [],
        };
    }

    async componentDidMount() {
        this.loadPayments();

        var selectedPayments = this.state.selectedPayments;

        if (selectedPayments.length)
        {
            selectedPayments = this.state.selectedPayments.map( x=> { return x.paymentArrangementId });

            const newSelected = Object.assign({}, this.state.selected);

            for (var i = 0; i < selectedPayments.length; i++)
            {
                newSelected[selectedPayments[i]] = !this.state.selected[selectedPayments[i]];
            }

            this.setState({
                selected: newSelected
            });
        }

    }

    toggleRow(paymentArrangementId) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[paymentArrangementId] = !this.state.selected[paymentArrangementId];

        this.setState({
            selected: newSelected,
            selectAll: 2
        });
        
        this.selectCalculate(paymentArrangementId, newSelected[paymentArrangementId]);
    }

    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.paymentArrangements.forEach(x => {
                newSelected[x.id] = true;
            });
        }

        this.selectAllCalculate(this.state.selectAll === 0 ? 1 : 0);

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
    
    selectCalculate(paymentArrangementId, isSelected) {

        var selectedPayments = JSON.parse(JSON.stringify(this.state.selectedPayments));
        var paymentArrangements = JSON.parse(JSON.stringify(this.state.paymentArrangements));

        //add
        if (isSelected) {
            selectedPayments.push(paymentArrangements.find(x => { return x.id === paymentArrangementId; }));
        }
        else {//remove
            var itemDeleted = selectedPayments.splice(selectedPayments.findIndex(item => { return item.id === paymentArrangementId; }), 1)[0];
        }

        this.setState({
            selectedPayments: selectedPayments,
        });

        this.props.resultPayments(selectedPayments);
    }

    selectAllCalculate(isSelectAll) {

        var selectedPayments = JSON.parse(JSON.stringify(this.state.selectedPayments));
        var paymentArrangements = JSON.parse(JSON.stringify(this.state.paymentArrangements));

        //add
        if (isSelectAll) {
            selectedPayments = paymentArrangements;
        }
        else {//remove
            selectedPayments = [];
        }

        this.setState({
            selectedPayments: selectedPayments,
        });
        
        this.props.resultPayments(selectedPayments);
    }

    loadPayments()
    {
        this.setState({ paymentArrangementLoading: true });
        let paymentArrangements = this.state.paymentArrangements;

        fetch(window.$SupplierServiceURL + '/api/PaymentArrangement/List',
        {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer' // no-referrer, *client
        })
        .then(res => {

            this.setState({ paymentArrangementLoading: false });

            switch (res.code) {

                case 401:
                    session.sessionExpired();
                    res = [];
                    return res;

                    break;

                case 403:
                    session.accessDenied();
                    res = [];
                    return res;

                    break;

                default:
                    return res.json();
            }

        }).then(res => {

            switch (res.code) {

                case 200:

                    if (!res.data) res.data = [];

                    if (res.data)
                        res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        paymentArrangements = paymentArrangements.concat(res.data);

                    this.setState({
                        paymentArrangements: paymentArrangements,
                        loading: false,
                    });

                    break;

                case 400:
                case 409:
                    var messages = res.data;
                    messages.forEach(ex => toast.warn(ex.message));
                    break;

                case 500:
                    toast.error('error');
                    break;
            }

        }).catch(err => {
            console.error(err);
        });
    }
    
    render() {
        return (
            <div className="body">
                <div className="m-b-40">
                    <ReactTable
                        columns={[
                            {
                                columns: [
                                    {
                                        Cell: ({ row }) => {
                                            return (
                                                <div>
                                                    <input
                                                        id={row.id}
                                                        type="checkbox"
                                                        checked={this.state.selected[row.id] === true}
                                                        onChange={() => this.toggleRow(row.id)}
                                                    />
                                                    <label htmlFor={row.id}></label>
                                                </div>
                                            );
                                        },
                                        Header: x => {
                                            return (
                                                <div>
                                                    <input
                                                        className="chk-col-white-border"
                                                        id="selectAll"
                                                        type="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                    <label htmlFor="selectAll"></label>
                                                </div>
                                            );
                                        },
                                        sortable: false,
                                        width: 45
                                    },
                                    {
                                        accessor: "id",
                                        show: false
                                    },
                                    {
                                        Header: translate('user.userName'),
                                        id: "name",
                                        accessor: data => { return <span title='Name'> {data.name}</span> }
                                    },
                                    {
                                        Header: translate('optIn.optInPaymentArrangementCode'),
                                        id: "code",
                                        accessor: data => { return  <span title='Code'> {data.code}</span> },
                                    },
                                    {
                                        Header: translate('optIn.optInPaymentArrangementType'),
                                        id: "type",
                                        accessor: data => { return <span title={enums.getPaymentStatus().find(x => { return x.index === parseInt(data.type); }).text}> {enums.getPaymentStatus().find(x => { return x.index === parseInt(data.type); }).text} </span> }
                                    }
                                ]
                            }
                        ]}
                        data={this.state.paymentArrangements}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        loading={this.state.loading}
                        previousText={translate('forms.previousText')}
                        nextText={translate('forms.nextText')}
                        noDataText={translate('forms.noDataText')}
                        pageText={translate('forms.pageText')}
                        ofText={translate('forms.ofText')}
                        rowsText={translate('forms.rowsText')}
                    />
                </div>
            </div>    
        )
    }
}