import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import * as session from '../components/SessionValidator';
import QueryString from 'query-string';
import { cpf_cnpj_Mask } from '../components/mask'

class BanksPage extends React.Component {

    displayName = BanksPage.name;

    constructor(props) {

        super(props);

        this.token = session.getToken();

        this.handlechangeMask = this.handlechangeMask.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.loadNextResults = this.loadNextResults.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);

        this.state = {

            banks: [],
            loading: true,
            search: '',
            searchDocument: '',

            query: {},
            tablePage: 0,
            tablePageSize: 10,
            tablePageCount: 0,
            numberOfResults: 0,
            currentPage: 0,
            rowCount: 0
        };
    }

    loadNextResults(page, pageSize) {

        if (pageSize === undefined) pageSize = this.state.tablePageSize;

        this.setState({ tablePage: page, tablePageSize: pageSize });
        let numberOfResults = this.state.numberOfResults;
        let numberOfRows = pageSize * (page + 1);
        let currentPage = this.state.currentPage;

        if (numberOfResults <= numberOfRows) {
            let nextPage = currentPage + 1;
            this.setState({ currentPage: nextPage });
            this.loadBanks();
        }

        let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
        this.setState({ tablePageCount: tablePageCount });
    }

    async componentDidMount() {
        this.loadBanks();
    }


    loadBanks() {
        let numberOfResults = this.state.numberOfResults;
        let banks = this.state.banks;
        this.state.query.page = this.state.currentPage + 1;
        this.state.query.pageSize = this.state.tablePageSize;
        this.state.query.name = this.state.searchName;
        this.state.query.cnpj = this.state.searchDocument;

        this.setState({ loading: true });

        fetch(window.$BankServiceURL + '/api/Bank/List/?' + QueryString.stringify(this.state.query),
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];
                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];
                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (res.code) {
                    case 200:
                        if (!res.data) res.data = [];

                        if (res.data)
                            res.data.result = res.data.result.sort(function (a, b) { return (a.legalName < b.legalName) ? -1 : (a.legalName > b.legalName) ? 1 : 0; });

                        banks = banks.concat(res.data.result);

                        numberOfResults = numberOfResults + (res.data.result == null ? 0 : res.data.result.length);

                        this.setState({
                            banks: banks,
                            loading: false,
                            numberOfResults: numberOfResults, currentPage: res.data.currentPage,
                            tablePageCount: res.data.pageCount, rowCount: res.data.rowCount
                        });

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        break;

                    case 500:
                        break;
                }
            });
    }

    handleSearchButton() {

        this.state.banks = [];
        this.state.currentPage = 0;
        this.state.numberOfResults = 0;
        this.state.tablePage = 0;
        this.state.tablePageSize = 10;
        this.state.tablePageCount = 0;
        this.state.rowCount = 0;

        this.setState(this.state);
        this.forceUpdate();

        this.loadBanks();
    }

    handleChange(event) {

        session.validateSession();
        this.change(event);
    }

    handlechangeMask(event) {
        event.target.value = cpf_cnpj_Mask(event.target.value);
        this.change(event);
    }

    change(event) {

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {
                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {
                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }
                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    render() {

        var search = this.state.search.toLowerCase();

        var bankItems = [];

        if (this.state.banks != null) {
            var bankItems = this.state.banks.filter(function (item) {

                return item.id.toString().toLowerCase().indexOf(search) >= 0 || item.legalName.toLowerCase().indexOf(search) >= 0 || item.cnpj.toLowerCase().indexOf(search) >= 0;

            });
        }

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                {translate('bank.banksTitle')}
                                <small>{translate('bank.banksTitleDescription')}</small>
                            </h2>
                        </div>

                        <div className="body">
                            <div className="row clearfix m-t-20">

                                <div className="col-md-3">
                                    <div className="input-group">

                                        <label htmlFor="txtName">
                                            {translate('customer.customerName')}
                                        </label>

                                        <div className="form-line">
                                            <input id="txtName" name="searchName" className="form-control" type="text" onChange={this.handleChange} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input-group">
                                        <label htmlFor="txtDocument">
                                            {translate('customer.customerDocumentPlaceHolder')}
                                        </label>

                                        <div className="form-line">
                                            <input id="txtDocument" name="searchDocument" maxLength='19' autoComplete="off" className="form-control" type="text" onChange={this.handlechangeMask} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 pull-left m-t-20">
                                    <button className="btn btn-block btn-primary" disabled={this.state.loading} type="button" onClick={event => { this.handleSearchButton(event) }}>
                                        <i className="material-icons">&#xe8b6;</i>
                                        <span>{translate('forms.buttonSearch')}</span>
                                    </button>
                                </div>

                                {(session.isAdmin() || session.isOperator()) && session.auth([{ type: "Bank", value: "Save" }]) &&
                                    <div className="col-md-3 m-t-20">
                                        <Link to={"/bank"}>
                                            <button type="button" className="btn btn-block btn-primary right">
                                            <i className="material-icons">&#xe145;</i>
                                                <span>{translate('bank.banksAddNew')}</span>
                                            </button>
                                        </Link>
                                    </div>
                                }
                            </div>

                            <ReactTable
                                data={bankItems}
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                maxWidth: 60
                                            },
                                            {
                                                Header: translate('bank.bankName'),
                                                accessor: "legalName"
                                            },
                                            {
                                                Header: translate('bank.bankCNPJ'),
                                                accessor: "cnpj"
                                            },
                                            {
                                                Header: translate('bank.bankIsActive'),
                                                Cell: row => {
                                                    return <div className="align-center">

                                                        {row.original.isActive &&
                                                            <i className="material-icons color-success">&#xe86c;</i>
                                                        }
                                                        {!row.original.isActive &&
                                                            <i className="material-icons color-danger">&#xe5c9;</i>
                                                        }

                                                    </div>
                                                },
                                                maxWidth: 100
                                            },
                                            (session.isAdmin() || session.isOperator()) && session.auth([{ type: "Bank", value: "Get" }]) &&
                                            {
                                                Cell: row => (
                                                    <div className="align-right">
                                                        <Link to={"/bank/" + row.original.id}>
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10">
                                                                <i className="material-icons">&#xe3c9;</i>
                                                            </button>
                                                        </Link>

                                                        <Link to={"/bankCustomers/" + row.original.id}>
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" title={translate('customer.customersTitle')}>
                                                                <i className="material-icons">&#xe937;</i>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                ),
                                                maxWidth: 200
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                loading={this.state.loading}
                                previousText={translate('forms.previousText')}
                                nextText={translate('forms.nextText')}
                                noDataText={translate('forms.noDataText')}
                                pageText={translate('forms.pageText')}
                                ofText={translate('forms.ofText')}
                                rowsText={translate('forms.rowsText')}

                                pages={this.state.tablePageCount}
                                page={this.state.tablePage}
                                pageSize={this.state.tablePageSize}
                                onPageSizeChange={(pageSize, page) => this.loadNextResults(page, pageSize)}
                                onPageChange={(page) => this.loadNextResults(page)}
                            />
                        </div>
                    </div>
                </div>

                <ToastContainer hideProgressBar />
            </div>
        )

    }

}

export default BanksPage;