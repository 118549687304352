import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HomePage from './pages/HomePage';

import LoginPage from './pages/loginPage';
import SignPage from './pages/signPage';
import LogoutPage from './pages/logoutPage';
import BanksPage from './pages/banksPage';
import BankPage from './pages/bankPage';
import BankCustomersPage from './pages/bankCustomersPage';
import UserPage from './pages/userPage';
import UsersPage from './pages/usersPage';
import ChangePasswordPage from './pages/changePasswordPage';
import CustomerPage from './pages/customerPage';
import CustomersPage from './pages/customersPage';
import CustomerSuppliersPage from './pages/customerSuppliersPage';
import SuppliersPage from './pages/suppliersPage';
import SupplierPage from './pages/supplierPage';
import InvoicesPage from './pages/invoicesPage';
import ReceivablesPage from './pages/receivablesPage';
import ImportNFPage from './pages/importNFPage';
import ImportUsersPage from './pages/importUsersPage';
import ImportSuppliersPage from './pages/importSuppliersPage';
import TransactionsPage from './pages/transactionsPage';
import DocumentsPage from './pages/documentsPage';
import DocumentPage from './pages/documentPage';
import TransactionPage from './pages/transactionPage';
import transactionViewPage from './pages/transactionViewPage';
import DailySummaryPage from './pages/dailySummaryPage';
import DocumentViewPage from './pages/documentViewPage';
import UseTermPage from './pages/useTermPage';
import AcceptTermPage from './pages/acceptTermPage';
import OptInListPage from './pages/optInListPage';
import optInPage from './pages/optInPage';
import ContractsPage from './pages/contractsPage';
import ContractPage from './pages/contractPage';

export default class Routes extends Component {

    constructor(props) {

        super(props);
        this.verifyAuth = this.verifyAuth.bind(this);
    }

    verifyAuth(componentToRender, listPermission) {

        if (componentToRender === SignPage) {
            return <Route component={componentToRender} />
        }

        var auth = localStorage.getItem("authp");

        if (!auth)
            return <HomePage />

        var sessionTimeOut = new Date();
        sessionTimeOut.setHours(sessionTimeOut.getHours() + 2);

        localStorage.setItem("authp", auth);

        localStorage.setItem('sessionTimeOut', sessionTimeOut);

        let passwordExpired = localStorage.getItem('PasswordExpired');

        if (passwordExpired === 'true') {
            return <Redirect to='/changePass' />
        }

        if (componentToRender === HomePage) {
            return <Route component={componentToRender} />
        }
        
        if (listPermission === undefined) {
            listPermission = [];
        }

        let roles = JSON.parse(localStorage.getItem('r'));

        if (!roles) {
            return <HomePage message="no-access" />
        }

        if (localStorage.getItem('ia') === 'true') {
            return <Route component={componentToRender} />
        }

        let permissions = roles.filter(function (item) {
            return listPermission.find(p => { return p.type == item.type && p.value == item.value; });
        })

        if (permissions.length == listPermission.length) {
            return <Route component={componentToRender} />
        }

        return <HomePage message="no-access" />
    }

    render() {

        return (
            <Switch>
                <Route exact path='/' render={() => this.verifyAuth(HomePage)} />

                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/sign/:id?' component={SignPage} />
                <Route exact path='/logout' component={LogoutPage} />
                <Route exact path='/home' render={() => this.verifyAuth(HomePage)} />
                <Route exact path='/banks' render={() => this.verifyAuth(BanksPage, [{ type: "Bank", value: "List" }])} />
                <Route exact path='/bank/:id?' render={() => this.verifyAuth(BankPage, [{ type: "Bank", value: "Get" }])} />
                <Route exact path='/bankCustomers/:id?' render={() => this.verifyAuth(BankCustomersPage, [{ type: "Bank", value: "Get" }])} />
                <Route exact path='/customer/:id?' render={() => this.verifyAuth(CustomerPage, [{ type: "Customer", value: "Get" }])} />
                <Route exact path='/customerSuppliers/:id?' render={() => this.verifyAuth(CustomerSuppliersPage, [{ type: "Customer", value: "Get" }])} />                
                <Route exact path='/customers' render={() => this.verifyAuth(CustomersPage, [{ type: "Customer", value: "List" }])} />
                <Route exact path='/suppliers' render={() => this.verifyAuth(SuppliersPage, [{ type: "Supplier", value: "List" }])} />
                <Route exact path='/supplier/:id?' render={() => this.verifyAuth(SupplierPage, [{ type: "Supplier", value: "Get" }])} />
                <Route exact path='/invoices' render={() => this.verifyAuth(InvoicesPage, [{ type: "Invoice", value: "List" }])} />
                <Route exact path='/receivables' render={() => this.verifyAuth(ReceivablesPage, [{ type: "Invoice", value: "List" }])} />
                <Route exact path='/importNF' render={() => this.verifyAuth(ImportNFPage, [{ type: "Invoice", value: "Import" }])} />
                <Route exact path='/importUsers' render={() => this.verifyAuth(ImportUsersPage, [{ type: "Account", value: "Get" }])} />
                <Route exact path='/importSuppliers' render={() => this.verifyAuth(ImportSuppliersPage, [{ type: "Supplier", value: "Get" }])} />
                <Route exact path='/transactions' render={() => this.verifyAuth(TransactionsPage, [{ type: "Transaction", value: "List" }])} />
                <Route exact path='/documents' render={() => this.verifyAuth(DocumentsPage, [{ type: "Document", value: "List" }])} />
                <Route exact path='/contracts' render={() => this.verifyAuth(ContractsPage, [{ type: "Document", value: "List" }])} />
                <Route exact path='/contract/:id?' render={() => this.verifyAuth(ContractPage, [{ type: "Supplier", value: "Get" }])} />
                <Route exact path='/document/:id?' render={() => this.verifyAuth(DocumentPage, [{ type: "Document", value: "Get" }])} />
                <Route exact path='/transaction/:id?' render={() => this.verifyAuth(TransactionPage, [{ type: "Transaction", value: "List" }])} />
                <Route exact path='/transactionView/:id?' render={() => this.verifyAuth(transactionViewPage, [{ type: "Transaction", value: "Get" }])} />
                <Route exact path='/optInList' render={() => this.verifyAuth(OptInListPage, [{ type: "Supplier", value: "List" }])} />
                <Route exact path='/optIn/:id?' render={() => this.verifyAuth(optInPage, [{ type: "Supplier", value: "Get" }])} />
                <Route exact path='/dailySummary' render={() => this.verifyAuth(DailySummaryPage, [{ type: "Account", value: "List" }])} />
                <Route exact path='/changepass' component={localStorage.getItem("authp") ? ChangePasswordPage : LoginPage} />
                <Route exact path='/users' render={() => this.verifyAuth(UsersPage, [{ type: "Account", value: "List" }])} />
                <Route exact path='/user/:id?' render={() => this.verifyAuth(UserPage, [{ type: "Account", value: "Get" }])} />
                <Route exact path='/documentview/:fileHash' render={() => this.verifyAuth(DocumentViewPage, [{ type: "Document", value: "Get" }])} />
                <Route exact path='/acceptTerm' component={AcceptTermPage} />
                <Route exact path='/useTerm' component={UseTermPage} />


                <Route render={function () {
                    return <h1>404 - Página não Encontrada</h1>;
                }} />
            </Switch>
        );

    }

}

