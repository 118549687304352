import $ from 'jquery';
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { translate } from 'react-internationalization';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { cnpj, cpf } from '../components/cpf-cnpj-validator';
import RCDatePicker from '../components/datePicker';
import * as enums from '../components/enums/user';
import { cpf_cnpj_Mask } from '../components/mask';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import { submitReportDailyLog } from './helper';
import ModalSupplierSearch from '../components/supplier/modal-supplier-search';

const moment = window.Moment;

class UserPage extends React.Component {

    constructor(props) {
        super(props);

        this.token = session.getToken();

        this.setValidators();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlechangeMask = this.handlechangeMask.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);

        this.handleChangeSupplierSearch = this.handleChangeSupplierSearch.bind(this);
        this.handleReturnSupplierSearch = this.handleReturnSupplierSearch.bind(this);
        this.handleResultSupplierSearch = this.handleResultSupplierSearch.bind(this);

        this.state = {
            user: null,

            selectedRoles: [],

            loading: true,

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },

            roles: null,
            customers: null,
            suppliers: null,
            banks: null,

            supplierSearchOpen: false,
            supplierLoading: true,
        };

        this.id = this.props.match.params.id;
    }

    setValidators() {

        this.validator = new SimpleReactValidator({

            emails: {

                rule: function (val) {

                    if (val === '') return true;
                    var emails = val.replace(new RegExp(',', 'g'), ';').split(";");
                    for (var i = 0, len = emails.length; i < len; i++) if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emails[i].trim())) return false;

                    return true;
                }
            },

            documentType: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },

            document: {

                rule: (value) => {

                    let documentType = this.state.user.documentType;

                    if (documentType == 0)//cnpj
                    {
                        return cnpj.isValid(value);
                    }
                    else if (documentType == 1) {//cpf
                        return cpf.isValid(value);
                    }
                    else {
                        return false;
                    }
                }
            },

            nationalIdentification: {

                rule: (value) => {

                    let documentType = this.state.user.documentType;

                    if (documentType == 0)//cnpj
                    {
                        return true;
                    }
                    else if (documentType == 1) {//cpf
                        return this.state.user.nationalIdentificationNumber != null && this.state.user.nationalIdentificationNumber != '';
                    }
                    else {
                        return false;
                    }
                }
            },

            birthDateOver18Years: {

                rule: (value) => {

                    let documentType = this.state.user.documentType;

                    if (documentType == 0)//cnpj
                    {
                        return true;
                    }
                    else if (documentType == 1) {//cpf
                        return window.Moment(this.state.user.birthDate, 'YYYY-MM-DD').isSameOrBefore(window.moment(new Date(), "YYYY-MM-DD").add('years', -18).format("YYYY-MM-DD"));
                    }
                }
            },
        });
    }

    async componentDidUpdate() {

        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });
    }

    async componentDidMount() {

        let _this = this;
        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        await this.loadRoles();

        let resStatus = 0;

        if (this.id) {

            await fetch(window.$AccountServiceURL + '/api/Account/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {
                    resStatus = res.status

                    if (resStatus === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();
                })
                .then(res => {

                    let messages = {};

                    switch (resStatus) {
                        case 200:

                            if (res.data.accountRole.length > 0) {

                                var rolesIds = res.data.accountRole.map(function (item) {
                                    return { id: item.roleId.toString() }
                                });

                                this.setState({ selectedRoles: rolesIds });
                            }

                            this.state.user = res.data;
                            this.setState(this.state);

                            let reportDailyLog = this.state.reportDailyLog;
                            reportDailyLog.objectBefore = JSON.stringify(res.data);
                            this.setState({ reportDailyLog: reportDailyLog });

                            break;
                        case 409:
                            messages = res.data;
                            messages.forEach(ex => toast.warn(ex.message));
                            break;
                        case 400:
                            messages = res.data;
                            messages.forEach(ex => toast.warn(ex.message));
                            break;
                        case 401:
                            session.sessionExpired();
                            break;
                        case 500:
                            break;
                    }
                });
        }
        else {
            let user = {
                type: 2,
                roles: [],
                customers: [],
                suppliers: [],
                banks: [],
                isActive: true,
                isAdmin: false,
                document: '',
                documentType: 0,
            }

            this.state.user = user;
        }

        if (resStatus === 200) {
            if (this.state.user.type === 1) {
                this.loadCustomers();
            }
            else if (this.state.user.type === 2) {

            }
            else if (this.state.user.type === 3) {
                this.loadBanks();
            }

            this.render();

            this.forceUpdate();
        }

        this.setState({ loading: false });
    }

    async loadRoles() {

        await fetch(window.$AccountServiceURL + '/api/Account/Roles',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                let resStatus = res.status

                if (resStatus === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();
            })
            .then(res => {

                if (res.data)
                    res.data = res.data.sort(function (a, b) { return (a.description < b.description) ? -1 : (a.description > b.description) ? 1 : 0; });

                this.setState({ roles: res.data });

                var roles = enums.getRoles(2);//supplier

                if (roles.length > 0) {

                    var rolesIds = roles.map(function (item) {
                        return { id: item.id.toString() }
                    });

                    this.setState({ selectedRoles: rolesIds });

                    var ids = roles.map(function (item) {
                        return item.id.toString();
                    });

                    $('#cmbRoles').multiSelect('select', ids);
                }

                this.forceUpdate();
            });
    }

    async loadCustomers() {

        await fetch(window.$CustomerServiceURL + '/api/Customer/ListActive',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();
            })
            .then(res => {

                if (res.data)
                    res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ customers: res.data });

                this.state['cmbLoading'] = false;

                this.setState(this.state);

                this.forceUpdate();
            });
    }

    handleChangeSupplierSearch(supplierId) {

        this.state.user.supplierId = supplierId;
        this.state.supplierSearchOpen = false;
        this.forceUpdate();
    }

    handleReturnSupplierSearch() {

        this.state.supplierSearchOpen = false;
        this.forceUpdate();
    }

    handleResultSupplierSearch(suppliers) {

        this.setState({ suppliers: suppliers, supplierLoading: false });
        this.getSupplier(this.state.user.supplierId);
        this.forceUpdate();
    }

    async getSupplier(id) {

        let suppliers = this.state.suppliers;

        if (id && this.state.user.type == 2 && !suppliers.find(x => x.id == id))//supplier
        {
            await fetch(window.$SupplierServiceURL + '/api/Supplier/GetSimpleById/' + id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];
                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];
                        return res;
                    }

                    return res.json();
                })
                .then(res => {

                    if (res.data) {

                        suppliers.push(res.data);

                        this.setState({ suppliers: suppliers });

                        this.setState(this.state);
                        this.forceUpdate();
                    }
                });
        }
    }

    async loadBanks() {

        await fetch(window.$BankServiceURL + '/api/Bank/ListActive',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];
                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];
                    return res;
                }

                return res.json();

            })
            .then(res => {

                if (res.data)
                    res.data = res.data.sort(function (a, b) { return (a.legalName < b.legalName) ? -1 : (a.legalName > b.legalName) ? 1 : 0; });

                this.setState({ banks: res.data });

                this.state['cmbLoading'] = false;

                this.setState(this.state);

                this.forceUpdate();
            });
    }

    handleTypingEmail(e) {
        e.target.value = e.target.value.toLowerCase()
    }

    handlechangeMask(event) {
        event.target.value = cpf_cnpj_Mask(event.target.value);

        this.handleChange(event);
    }

    handleChangeType(event) {

        session.validateSession();

        this.state.user.isAdmin = false;

        //1 = customer / 2 = supplier / 3 = bank / 4 = operador
        if (event.target.value === 1 || event.target.value === 2 || event.target.value === 3 || event.target.value === 4) {

            $('#cmbRoles').multiSelect('deselect_all');

            var roles = enums.getRoles(event.target.value);

            if (roles.length > 0) {

                var ids = roles.map(function (item) {
                    return item.id.toString();
                });

                $('#cmbRoles').multiSelect('select', ids);
            }

            this.state.user.customerId = null;
            this.state.user.supplierId = null;
            this.state.user.bankId = null;

            this.state.customers = [];
            this.state.suppliers = [];
            this.state.banks = [];

            //se não for operador
            if (event.target.value != 4)
                this.state['cmbLoading'] = true;

            if (event.target.value === 1) {
                this.loadCustomers();
            }
            else if (event.target.value === 2) {

            }
            else if (event.target.value === 3) {
                this.loadBanks();
            }
        }
        else if (event.target.value === 0) { // 0 = administrator
            $('#cmbRoles').multiSelect('select_all');

            this.state.user.isAdmin = true;
        }

        this.state.user[event.target.name] = event.target.value;
    }

    handleChange(event) {

        session.validateSession();

        if (event.target.type === 'checkbox') {
            this.state.user[event.target.name] = event.target.checked
        }
        else {

            this.state.user[event.target.name] = event.target.value;
        }

        this.setState(this.state);
    }

    handleSubmit(event) {

        if (this.state.user.documentType == 0)//cnpj
        {
            this.validator.fields['birthDate'] = true;
        }

        if (!this.validator.allValid()) {

            this.validator.showMessages();
            this.forceUpdate();

            return;
        }

        let method = this.id ? 'Update' : 'Create';
        let status = 0;

        this.state.user.accountRole = [];

        let reportDailyLog = this.state.reportDailyLog;

        for (var i = 0; i < this.state.selectedRoles.length; i++) {
            this.state.user.accountRole.push({ roleId: this.state.selectedRoles[i].id });
        }

        this.state.loading = true;
        this.setState(this.state);

        var user = $.extend(true, {}, this.state.user);

        fetch(window.$AccountServiceURL + '/api/Account/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(user),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            }
        ).then(res => {

            status = res.status
            this.setState({ loading: false });

            switch (status) {

                case 401:
                    session.sessionExpired();
                    res = [];
                    return res;

                    break;

                case 403:
                    session.accessDenied();
                    res = [];
                    return res;

                    break;

                default:
                    return res.json();
            }

        }).then(res => {

            switch (status) {

                case 200:

                    toast.success(translate(!this.id ? 'enums.logAction.createAccount' : 'enums.logAction.updateAccount'));

                    reportDailyLog.action = this.id ? 6 : 5;//5 = createAccount 6 = updateAccount
                    reportDailyLog.objectAfter = JSON.stringify(user);
                    submitReportDailyLog(reportDailyLog);

                    this.props.history.push('/users');

                    break;

                case 400:
                case 409:
                    var messages = res.data;
                    messages.forEach(ex => toast.warn(ex.message));
                    break;

                case 500:
                    toast.error('error');
                    break;
            }

        }).catch(err => {
            console.error(err);
        });
    }

    render() {

        var fetched = this.state != null && this.state.user != null && this.state.roles;

        if (fetched) {

            if ($('.ms-selection').length === 0) {

                let _this = this;

                $('#cmbRoles').multiSelect({
                    selectableHeader: '<div className="custom-header">' + translate('user.userRolesDenied') + '</div>',
                    selectionHeader: '<div className="custom-header">' + translate('user.userRolesGranted') + '</div>',
                    afterSelect: function (values) {

                        let selectedRoles = [];

                        if (values.length == 1) {
                            selectedRoles = _this.state.selectedRoles;

                            if (selectedRoles.findIndex(item => { return item.id === values[0]; }) === -1)
                                selectedRoles.push({ id: values[0] });
                        }
                        else {

                            selectedRoles = values.map(function (item) {
                                return { id: item }
                            });
                        }

                        _this.setState({ selectedRoles: selectedRoles });
                    },
                    afterDeselect: function (values) {

                        let selectedRoles = [];

                        if (values.length > 0) {
                            selectedRoles = _this.state.selectedRoles;
                            var role = selectedRoles.splice(selectedRoles.findIndex(item => { return item.id === values[0]; }), 1)[0];
                        }

                        _this.setState({ selectedRoles: selectedRoles });
                    }
                });

                var x = []

                for (var i = 0; i < _this.state.selectedRoles.length; i++) {
                    x.push(_this.state.selectedRoles[i].id.toString());
                }

                if (x.length === 0) {
                    $('#cmbRoles').multiSelect('deselect_all');
                } else {
                    $('#cmbRoles').multiSelect('select', x);
                }
            }
        }

        return (

            fetched ?
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    {translate('user.userTitle')}
                                    <small>{translate('user.userTitleDescription')}</small>
                                </h2>
                                <ul className="header-dropdown m-r--5">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.loading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="body">
                                <div className="row clearfix">
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label htmlFor="txtName" className="validationRequired">{translate('user.userName')}</label>

                                            <div className="form-line">
                                                <input type="text" id="txtName" name="name" className="form-control" value={this.state.user.name} onChange={this.handleChange} placeholder={translate('user.userNamePlaceholder')} maxLength="100" autoComplete="off" />
                                            </div>

                                            {this.validator.message('name', this.state.user.name, 'required', 'validationMessage--error', { default: translate('user.userNameRequired') })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label htmlFor="txtEmail" className="validationRequired">{translate('user.userEmail')}</label>
                                            <div className="form-line">
                                                <input type="email" id="txtEmail" name="email" className="form-control" value={this.state.user.email} onChange={this.handleChange} onKeyUp={this.handleTypingEmail} disabled={this.id} placeholder={translate('user.userEmailPlaceholder')} maxLength="100" autoComplete="off" />
                                            </div>
                                            {this.validator.message('email', this.state.user.email, 'required|emails', false, { default: translate('user.userEmailRequired'), emails: translate('user.userEmailInvalid') })}
                                        </div>
                                    </div>
                                </div>

                                <div className="row clearfix">

                                    <div className={this.state.user.documentType === 1 ? 'col-md-2' : 'col-md-3'}>
                                        <div className="m-b-20">
                                            <label htmlFor="cmbDocumentType" className="validationRequired">
                                                {translate('supplier.supplierDocumentType')}
                                            </label>

                                            <RCSelect
                                                id="cmbDocumentType"
                                                name="documentType"
                                                hasEmptyOption={true}
                                                isInt={true}
                                                options={enums.getDocumentType()}
                                                optionValue="index"
                                                optionLabel="text"
                                                placeholder={translate('supplier.supplierDocumentTypePlaceHolder')}
                                                onChange={event => { this.handleChange(event); }}
                                                value={this.state.user.documentType}
                                            />

                                            {this.validator.message('documentType', this.state.user.documentType, 'required|documentType', false, { default: translate('supplier.supplierDocumentTypeRequired') })}
                                        </div>
                                    </div>

                                    <div className={this.state.user.documentType === 1 ? 'col-md-2' : 'col-md-3'}>
                                        <div className="input-group">
                                            <label htmlFor="txtDocument" className="validationRequired"> {translate('supplier.supplierDocument')} </label>

                                            <div className="form-line">
                                                <input id="txtDocument" name="document" maxLength='19' value={this.state.user.document} onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('supplier.supplierDocumentPlaceHolder')} type="text" />
                                            </div>

                                            {this.validator.message('document', this.state.user.document, 'required|document', false, { default: translate('supplier.supplierDocumentRequired'), document: this.state.user.documentType == 0 ? translate('supplier.supplierCNPJInvalid') : translate('supplier.supplierCPFInvalid') })}
                                        </div>
                                    </div>

                                    <div className={this.state.user.documentType === 1 ? 'col-md-2' : 'col-md-3'} style={{ display: this.state.user.documentType === 1 ? 'block' : 'none' }}>
                                        <div className="input-group">
                                            <label htmlFor="txtNationalIdentificationNumber" className="validationRequired"> {translate('user.userNationalIdentificationNumber')} </label>

                                            <div className="form-line">
                                                <input id="txtNationalIdentificationNumber" value={this.state.user.nationalIdentificationNumber} name="nationalIdentificationNumber" maxLength='20' className="form-control" onChange={this.handleChange} autoComplete="off" placeholder={translate('user.userNationalIdentificationNumberPlaceHolder')} type="text" />
                                            </div>

                                            {this.validator.message('nationalIdentificationNumber', this.state.user.nationalIdentificationNumber, 'nationalIdentification', false, { default: translate('user.userNationalIdentificationNumberRequired') })}
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="m-t-30">
                                            <input type="checkbox" id="chkIsActive" name="isActive" checked={this.state.user.isActive} onChange={this.handleChange} />
                                            <label htmlFor="chkIsActive">{translate('user.userIsActive')}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="m-t-30">
                                            <input type="checkbox" id="chkIsAdmin" name="isAdmin" disabled={!session.isAdmin()} checked={this.state.user.isAdmin} onChange={this.handleChange} />
                                            <label htmlFor="chkIsAdmin">{translate('user.userIsAdmin')}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="m-t-30">
                                            <input type="checkbox" id="chkIsBlocked" name="isBlocked" disabled={!session.isAdmin()} checked={this.state.user.isBlocked} onChange={this.handleChange} />
                                            <label htmlFor="chkIsBlocked">{translate('user.userIsBlocked')}</label>
                                        </div>
                                    </div>

                                </div>

                                <div className="row clearfix">

                                    <div className={this.state.user.documentType === 1 ? 'col-md-3' : 'col-md-6'}>
                                        <div className="input-group">
                                            <label htmlFor="txtPhoneNumber">{translate('user.userPhoneNumber')}</label>

                                            <div className="form-line">
                                                <input id="txtPhoneNumber" maxLength="15" className="form-control js-change js-phone-number" value={this.state.user.phoneNumber} onChange={this.handleChange} name="phoneNumber" placeholder={translate('user.userPhoneNumberPlaceHolder')} type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3" style={{ display: this.state.user.documentType === 1 ? 'block' : 'none' }}>
                                        <div className="input-group">
                                            <label htmlFor="dtBirthDate">{translate('user.userBirthDate')}</label>

                                            <div className="form-line">
                                                <RCDatePicker id="dtBirthDate" name="birthDate" value={this.state.user.birthDate} onChange={this.handleChange} />
                                            </div>

                                            {this.validator.message('birthDate', this.state.user.birthDate, 'required|birthDateOver18Years', false, { default: translate('user.userBirthDateRequired'), birthDateOver18Years: translate('user.userBirthDateOver18Years') })}
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="m-t-30">
                                            <input type="checkbox" id="chkSignContract" name="signContract" checked={this.state.user.signContract} onChange={this.handleChange} />
                                            <label htmlFor="chkSignContract">{translate('user.userSignContract')}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-3" style={{ display: session.isAdmin() ? 'block' : 'none' }}>
                                        <div className="input-group">
                                            <label htmlFor="txtPassword">{translate('user.changePasswordPassword')}</label>

                                            <div className="form-line">
                                                <input type="text" id="txtPassword" name="password" className="form-control" value={this.state.user.password} onChange={this.handleChange} placeholder={translate('user.changePasswordPassword')} maxLength="100" autoComplete="off" />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row clearfix">

                                    <div className="col-md-6">
                                        <div className="input-group">

                                            <label htmlFor="cmbType">
                                                {translate('user.userType')}
                                            </label>

                                            <RCSelect
                                                id="cmbType"
                                                name="type"
                                                hasEmptyOption={false}
                                                isInt={true}
                                                options={enums.getTypes()}
                                                optionValue="index"
                                                optionLabel="text"
                                                placeholder={translate('user.userTypePlaceHolder')}
                                                onChange={event => { this.handleChangeType(event); }}
                                                value={this.state.user.type}
                                            />
                                            {this.validator.message('cmbType', this.state.user.type, 'required', false, { default: translate('user.userTypeRequired') })}
                                        </div>

                                    </div>

                                    <div className="col-md-6" style={{ display: this.state.user.type == 1 ? 'unset' : 'none' }}>
                                        <div className="m-b-5">
                                            <label htmlFor="cmbCustomer"> {translate('customer.customersTitle')} </label>

                                            <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.cmbLoading ? 'inline-block' : 'none' }}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <RCSelect
                                                id="cmbCustomer"
                                                name="customerId"
                                                hasEmptyOption={true}
                                                isInt={true}
                                                options={this.state.customers}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder={translate('user.userCustomerPlaceHolder')}
                                                onChange={event => { this.handleChange(event); }}
                                                value={this.state.user.customerId}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-5" style={{ display: this.state.user.type == 2 ? 'unset' : 'none' }}>
                                        <div className="m-b-5">
                                            <label htmlFor="cmbSupplier"> {translate('supplier.suppliersTitle')} </label>

                                            <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.supplierLoading ? 'inline-block' : 'none' }}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <RCSelect
                                                id="cmbSupplier"
                                                name="supplierId"
                                                hasEmptyOption={true}
                                                isInt={true}
                                                options={this.state.suppliers}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder={translate('user.userSupplierPlaceHolder')}
                                                onChange={event => { this.handleChange(event); }}
                                                value={this.state.user.supplierId}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-1 m-t-20 p-l-0" style={{ display: this.state.user.type == 2 ? 'unset' : 'none' }}>
                                        <button type="button" className="btn btn-default waves-effect" onClick={() => {
                                            this.state.supplierSearchOpen = true;
                                            this.forceUpdate();
                                        }}>
                                            <i className="material-icons">&#xe8b6;</i>
                                        </button>

                                        <ModalSupplierSearch
                                            customerId={null}
                                            SearchOpen={this.state.supplierSearchOpen}
                                            handleChange={event => { this.handleChangeSupplierSearch(event); }}
                                            handleResult={event => { this.handleResultSupplierSearch(event); }}
                                            handleReturn={() => { this.handleReturnSupplierSearch(); }}
                                        />
                                    </div>

                                    <div className="col-md-6" style={{ display: this.state.user.type == 3 ? 'unset' : 'none' }}>
                                        <div className="m-b-5">
                                            <label htmlFor="cmbBank"> {translate('bank.banksTitle')} </label>

                                            <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.cmbLoading ? 'inline-block' : 'none' }}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <RCSelect
                                                id="cmbBank"
                                                name="bankId"
                                                hasEmptyOption={true}
                                                isInt={true}
                                                options={this.state.banks}
                                                optionValue="id"
                                                optionLabel="legalName"
                                                placeholder={translate('user.userBankPlaceHolder')}
                                                onChange={event => { this.handleChange(event); }}
                                                value={this.state.user.bankId}
                                            />

                                        </div>
                                    </div>

                                </div>

                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <p>
                                            <b>{translate('user.userRoles')}</b>
                                        </p>

                                        <select id="cmbRoles" multiple value={this.state.selectedRoles} className="ms js-roles" onChange={this.handleChange}>
                                            {
                                                this.state.roles.map((role) => <option key={role.id} value={role.id}>{role.description}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 formActions">
                                        <Link to="/users" className="btn btn-default waves-effect">
                                            <i className="material-icons">&#xe5c4;</i><span>{translate('forms.buttonReturn')}</span>
                                        </Link>

                                        <button type="button" className="btn btn-success waves-effect" disabled={this.state.loading} onClick={this.handleSubmit}>
                                            <i className="material-icons">&#xe161;</i>
                                            <span>{translate('forms.buttonSave')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer hideProgressBar />
                </div >
                : <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default UserPage;
