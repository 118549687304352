import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import RCDatePicker from '../components/datePicker';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import SimpleReactValidator from 'simple-react-validator'
import $ from 'jquery';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import { cnpj } from '../components/cpf-cnpj-validator';
import { cpf_cnpj_Mask } from '../components/mask'
import { submitReportDailyLog } from './helper';
import NumberFormat from 'react-number-format';
import * as enums from '../components/enums/customer';
import * as enumsBank from '../components/enums/bank';
import QueryString from 'query-string';
import AddressCustomerComponent from '../components/customer/AddressCustomerComponent';
import ContactCustomerComponent from '../components/customer/ContactCustomerComponent';
import BankCustomerComponent from '../components/customer/BankCustomerComponent';
import ClassificationCustomerComponent from '../components/customer/ClassificationCustomerComponent';

const moment = window.Moment;

class CustomerPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();

        this.setValidators();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


        this.state = {

            id: this.props.match.params.id,

            customer: {
                portalRate: 0
            },

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },

            balance: { open: false },

            currentBalanceAvailable: '0,00',
            currentBalanceDueDate: ''
        };
    }

    handlechangeMask(e) {

        e.target.value = cpf_cnpj_Mask(e.target.value);
    }

    async componentDidMount() {

        let _this = this;
        let reportDailyLog = this.state.reportDailyLog;

        //Tooltip
        $('[data-toggle="tooltip"]').tooltip();

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        if (this.id) {

            await fetch(window.$CustomerServiceURL + '/api/customer/GetSimpleById/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    reportDailyLog.objectBefore = JSON.stringify(res.data);

                    if (res.data) {

                        if (!res.data.balanceDueDate) {
                            res.data.balanceDueDate = moment;
                        }

                        this.setState({ customer: this.formatData(res.data), reportDailyLog: reportDailyLog, currentBalanceDueDate: res.data.balanceDueDate, currentBalanceAvailable: res.data.balanceAvailable });
                    }

                    this.forceUpdate();
                });

        } else {

            let customer = {

                isActive: true,
                address: [],
                contact: [],
                bankAccount: [],
                classification: [],
                balanceAvailable: '0,00',
                balanceDueDate: moment().format()
            }

            this.setState({ customer: customer });
            this.forceUpdate();
        }
    }

    async componentDidUpdate() {

        let _this = this;

        $('.js-change').each(function () { _this.setValue(this); });
        $('.js-integer').inputmask({ greedy: false, mask: '9', repeat: '*', showMaskOnHover: false });
        $('.js-decimal').inputmask('decimal', { digits: 4, digitsOptional: false, max: 999999999, placeholder: '0.00', rightAlign: false, showMaskOnHover: false });
        $('.js-letters').inputmask({ greedy: false, mask: 'a', repeat: '*', showMaskOnHover: false });
    }

    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    formatData(data) {

        return data;
    }

    handleSubmit() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();
            this.forceUpdate();

            return;
        }

        var customer = $.extend(true, {}, this.state.customer),
            method = this.id ? 'Update' : 'Create',
            status = 0;

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ submitLoading: true });

        fetch(window.$CustomerServiceURL + '/api/Customer/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(customer),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                status = res.status;
                this.setState({ submitLoading: false });

                switch (status) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (status) {

                    case 200:
                        toast.success(translate(!this.id ? 'customer.customerCreated' : 'customer.customerUpdated'));

                        reportDailyLog.action = this.id ? 3 : 2;//2 = createCustomer 3 = updateCustomer
                        reportDailyLog.objectAfter = JSON.stringify(customer);
                        submitReportDailyLog(reportDailyLog);

                        this.state.id = res.data.id;
                        this.forceUpdate();

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    setValidators() {

        this.validator = new SimpleReactValidator({});
        
        this.balanceValidator = new SimpleReactValidator({

            balanceDueDateLaterThanCurrentDate: {

                rule: () => {

                    return !this.state.currentBalanceDueDate || window.Moment(this.state.currentBalanceDueDate, 'YYYY-MM-DD').isSameOrAfter(window.Moment(new Date(), 'YYYY-MM-DD'));
                }
            },
        });
    }

    handleEditBalance() {

        this.state.balance.open = true;
        this.state.balanceBackup = $.extend(true, {}, this.state.balance);

        this.balanceValidator.hideMessages();
        this.forceUpdate();
    }

    handleSaveBalance() {

        let valid = this.balanceValidator.allValid();

        if (valid) {

            let balance = this.state.balance;

            this.setState({ balance: balance, balanceBackup: undefined });

            let queryUpdateBalance = {};

            //format
            let balanceAvailable = 0;

            if (this.state.currentBalanceAvailable.toString().includes("R$")) 
                balanceAvailable = parseFloat(this.state.currentBalanceAvailable.toString().replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
            else
                balanceAvailable = this.state.currentBalanceAvailable;

            queryUpdateBalance.id = this.id;
            queryUpdateBalance.value = balanceAvailable;
            queryUpdateBalance.dueDate = this.state.currentBalanceDueDate;

            this.setState({ submitLoading: true });

            fetch(window.$CustomerServiceURL + '/api/Customer/UpdateBalanceAvailable/?' + QueryString.stringify(queryUpdateBalance),
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    switch (res.status) {

                        case 401:
                            session.sessionExpired();
                            res = [];
                            return res;

                            break;

                        case 403:
                            session.accessDenied();
                            res = [];
                            return res;

                            break;

                        default:
                            return res.json();
                    }

                }).then(res => {

                    switch (res.code) {

                        case 200:
                            toast.success(translate('customer.customerBalanceUpdated'));

                            break;

                        case 400:
                        case 409:
                            var messages = res.data;
                            messages.forEach(ex => toast.warn(ex.message));
                            break;

                        case 500:
                            toast.error('error');
                            break;
                    }

                    balance.open = false;
                    this.setState({ submitLoading: false, balance: balance });

                }).catch(err => {
                    console.error(err);
                });

        } else {

            this.balanceValidator.showMessages();
            this.forceUpdate();
        }
    }

    handleReturnBalance() {

        let balance = $.extend(true, {}, this.state.balanceBackup);
        balance.open = false;

        this.setState({ balance: balance, balanceBackup: undefined });
    }

    render() {

        var fetched = this.state != null && this.state.customer != null;

        if (fetched) {

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    <ul className="nav nav-tabs tab-nav-right" role="tablist">
                                        <li className="active" role="presentation">
                                            <a href="#customerPanel" data-toggle="tab">{translate('customer.customerTitle')}</a>
                                        </li>

                                        <li role="presentation" className={this.state.id ? "" : "disabled"}>
                                            <a id="addresses-tab-link" href={this.state.id ? "#addressPanel" : "#"} data-toggle="tab">{translate('customer.customerAddressNameTitle')}</a>
                                        </li>

                                        <li role="presentation" className={this.state.id ? "" : "disabled"}>
                                            <a href={this.state.id ? "#contactsPanel" : "#"} data-toggle="tab">{translate('customer.customerContactsTitle')}</a>
                                        </li>

                                        <li role="presentation" className={this.state.id ? "" : "disabled"}>
                                            <a id="bankAccounts-tab-link" href={this.state.id ? "#bankAccountsPanel" : "#"} data-toggle="tab">{translate('customer.customerSetupAccountTitle')}</a>
                                        </li>

                                        <li role="presentation" className={this.state.id ? "" : "disabled"}>
                                            <a href={this.state.id ? "#classificationsPanel" : "#"} data-toggle="tab">{translate('customer.customerClassificationsTitle')}</a>
                                        </li>

                                    </ul>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="tab-content">
                                    <div id="customerPanel" className="tab-pane fade in active" role="tabpanel">
                                        <div>
                                            <div className="m-b-40">
                                                <small>{translate('customer.customerTitleDescription')}</small>
                                            </div>

                                            <div className="row clearfix">
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <label className="validationRequired" htmlFor="txtName">{translate('customer.customerName')}</label>

                                                        <div className="form-line">
                                                            <input id="txtName" disabled={!session.auth([{ type: "Customer", value: "Save" }])} className="form-control js-change" name="customer.name" placeholder={translate('customer.customerNamePlaceHolder')} type="text" />
                                                        </div>

                                                        {this.validator.message('customer.name', this.state.customer.name, 'required', 'validationMessage--error', { default: translate('customer.customerNameRequired') })}
                                                    </div>
                                                </div>

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-3">
                                                        <div className="m-t-30">
                                                            <input id="chkIsActive" name="customer.isActive" type="checkbox" checked={this.state.customer.isActive} onChange={this.handleChange} />
                                                            <label htmlFor="chkIsActive">{translate('customer.customerIsActive')}</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row clearfix">

                                                <div className="col-md-3">
                                                    <div className="m-b-20">
                                                        <label htmlFor="cmbTypeApproval" className="validationRequired">
                                                            {translate('customer.customerTypeApproval')}
                                                        </label>

                                                        <RCSelect
                                                            id="cmbTypeApproval"
                                                            name="customer.typeApproval"
                                                            hasEmptyOption={true}
                                                            isInt={true}
                                                            options={enums.getTypeApproval()}
                                                            optionValue="index"
                                                            optionLabel="text"
                                                            placeholder={translate('customer.customerTypeApprovalPlaceHolder')}
                                                            onChange={event => { this.handleChange(event); }}
                                                            value={this.state.customer.typeApproval}
                                                            isDisabled={!session.auth([{ type: "Customer", value: "Save" }])}
                                                        />

                                                        {this.validator.message('cmbTypeApproval', this.state.customer.typeApproval, 'required', false, { default: translate('customer.customerTypeApprovalRequired') })}
                                                    </div>
                                                </div>

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label htmlFor="txtPortalRate">{translate('customer.customerPortalRate')}</label>

                                                            <div className="form-line">
                                                                <input id="txtPortalRate" className="form-control js-change js-decimal" name="customer.portalRate" placeholder={translate('customer.customerPortalRatePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.validator.message('customer.portalRate', this.state.customer.portalRate, 'gt: 0', false, { default: translate('customer.customerPortalRateRequired') })}
                                                        </div>
                                                    </div>
                                                }

                                                {(session.isAdmin()) &&
                                                    <div className="col-md-3">
                                                        <div className="m-t-30">
                                                        <input id="chkTransactionAnalysis" name="customer.transactionAnalysis" type="checkbox" checked={this.state.customer.transactionAnalysis} onChange={this.handleChange} />
                                                        <label data-toggle="tooltip" data-placement="top"
                                                            title={translate('customer.transactionAnalysisTooltip')}
                                                            htmlFor="chkTransactionAnalysis">{translate('customer.transactionAnalysis')}</label>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-md-12 formActions">
                                                    <Link to="/customers" className="btn btn-default waves-effect">
                                                        <i className="material-icons">&#xe5c4;</i>
                                                        <span>{translate('forms.buttonReturn')}</span>
                                                    </Link>

                                                    {(session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        <button className="btn bg-deep-purple waves-effect m-l-10" style={{ display: this.id ? 'inline-block' : 'none' }} type="button" onClick={() => { this.handleEditBalance(); }}>
                                                            <i className="material-icons">&#xe263;</i>
                                                            <span>{translate('forms.updateBalance')}</span>
                                                        </button>
                                                    }

                                                    {session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={this.handleSubmit}>
                                                            <i className="material-icons">&#xe161;</i>
                                                            <span>{translate('forms.buttonSave')}</span>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="addressPanel" className="tab-pane fade in" role="tabpanel">
                                        <div>
                                            <AddressCustomerComponent
                                                customerId={this.state.id}
                                                handleChange={event => { this.forceUpdate(); }}
                                            />

                                            <div className="row clearfix">
                                                <div className="col-md-12 formActions">
                                                    <Link to="/customers" className="btn btn-default waves-effect">
                                                        <i className="material-icons">&#xe5c4;</i>
                                                        <span>{translate('forms.buttonReturn')}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="contactsPanel" className="tab-pane fade in" role="tabpanel">
                                        <ContactCustomerComponent
                                            customerId={this.state.id}
                                            handleChange={event => { this.forceUpdate(); }}
                                        />
                                        <div className="row clearfix">
                                            <div className="col-md-12 formActions">
                                                <Link to="/customers" className="btn btn-default waves-effect">
                                                    <i className="material-icons">&#xe5c4;</i>
                                                    <span>{translate('forms.buttonReturn')}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="bankAccountsPanel" className="tab-pane fade in" role="tabpanel">
                                        <BankCustomerComponent
                                            customerId={this.state.id}
                                            handleChange={event => { this.forceUpdate(); }}
                                        />
                                        <div className="row clearfix">
                                            <div className="col-md-12 formActions">
                                                <Link to="/customers" className="btn btn-default waves-effect">
                                                    <i className="material-icons">&#xe5c4;</i>
                                                    <span>{translate('forms.buttonReturn')}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="classificationsPanel" className="tab-pane fade in" role="tabpanel">
                                        <ClassificationCustomerComponent
                                            customerId={this.state.id}
                                            handleChange={event => { this.forceUpdate(); }}
                                        />
                                        <div className="row clearfix">
                                            <div className="col-md-12 formActions">
                                                <Link to="/customers" className="btn btn-default waves-effect">
                                                    <i className="material-icons">&#xe5c4;</i>
                                                    <span>{translate('forms.buttonReturn')}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Balance */}
                    <Modal
                        open={this.state.balance.open}
                        closeOnEsc={false}
                        onClose={() => { }}
                        onEntered={() => { this.forceUpdate(); }}
                        closeIconSize={0}
                        center={true}
                        styles={{ modal: { maxWidth: '60%' } }}
                    >
                        <div>
                            <div style={{ width: 9999 }}></div>
                            <div className="row">
                                <div className="col-md-11 formActions">
                                    <h4>{translate('forms.updateBalance')}</h4>
                                </div>
                                <div className="col-md-1 formActions">
                                    <div className="header-dropdown" style={{ float: 'right' }}>
                                        <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                            <div className="spinner-layer">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>

                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <p>
                                                {translate('customer.customerBalanceAvailable')}{': '}
                                                <b>
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.customer.balanceAvailable)}
                                                </b>
                                            </p>

                                            <div className="form-line">

                                                <NumberFormat prefix="R$ " placeholder={translate('customer.customerBalanceAvailablePlaceHolder')} className="form-control" value={this.state.currentBalanceAvailable} thousandSeparator={true} thousandSeparator={'.'} decimalSeparator={','} onValueChange={(values) => {
                                                    const { formattedValue, value } = values;

                                                    let currentBalanceAvailable = this.state.currentBalanceAvailable;
                                                    currentBalanceAvailable = formattedValue;

                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    this.setState({ currentBalanceAvailable: currentBalanceAvailable })
                                                }} />
                                            </div>

                                            {this.balanceValidator.message('currentBalanceAvailable', this.state.currentBalanceAvailable, 'required', false, { default: translate('customer.customerBalanceAvailableRequired') })}
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <p>
                                                {translate('customer.customerBalanceDueDate')}:
													<b> {this.state.currentBalanceDueDate ? window.Moment(this.state.currentBalanceDueDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}</b>
                                            </p>

                                            <div className="form-line">
                                                <RCDatePicker id="dtBalanceDueDate" name="currentBalanceDueDate" value={this.state.currentBalanceDueDate} onChange={this.handleChange} />
                                            </div>

                                            {this.balanceValidator.message('currentBalanceDueDate', this.state.currentBalanceDueDate, 'required|balanceDueDateLaterThanCurrentDate', false, { default: translate('customer.customerBalanceDueDateRequired'), balanceDueDateLaterThanCurrentDate: translate('customer.customerBalanceDueDateLaterThanEndDate') })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="align-right">
                                <div className="m-t-20">

                                    <button type="button" className="m-r-10 btn btn-default waves-effect" disabled={this.state.submitLoading} onClick={() => { this.handleReturnBalance(); }}>
                                        <i className="material-icons">&#xe5c4;</i>
                                        <span>{translate('forms.buttonReturn')}</span>
                                    </button>

                                    <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={() => { this.handleSaveBalance(); }}>
                                        <i className="material-icons">&#xe161;</i>
                                        <span>{translate('forms.updateBalance')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ToastContainer hideProgressBar />
                </div>
            )

        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {

    function handleClick() {

        tabToShow.trigger('click');
        closeToast();
    }

    return (

        <div onClick={handleClick}>
            {message}
        </div>
    );
}

export default CustomerPage;