import React from 'react';
import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';

import {
    InternationalizationProvider,
    internationalize
} from 'react-internationalization'

import * as languages from '../src/translations';

import './include/bootstrap';
import './components/admin';
import './App.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

//origem
window.$ClientId = 'PortalDefault'

window.$AppTitle = 'BANKFLIX'
window.$AppURL = 'https://www.bankflixapp.com/'

//URL Services

//Local
//window.$AccountServiceURL = 'http://localhost:15287/v1'
//window.$BankServiceURL = 'http://localhost:55505/v1'
//window.$CustomerServiceURL = 'http://localhost:5007/v1'
//window.$DocumentServiceURL = 'http://localhost:55552/v1'
//window.$LogServiceURL = 'http://localhost:55551/v1'
//window.$SupplierServiceURL = 'http://localhost:5006/v1'
//window.$TransactionServiceURL = 'http://localhost:56001/v1'

////Server prod
window.$AccountServiceURL = 'https://api.portal.bankflixapp.com/account/service/v1'
window.$BankServiceURL = 'https://api.portal.bankflixapp.com/bank/service/v1'
window.$CustomerServiceURL = 'https://api.portal.bankflixapp.com/customer/service/v1'
window.$DocumentServiceURL = 'https://api.portal.bankflixapp.com/document/service/v1'
window.$LogServiceURL = 'https://api.portal.bankflixapp.com/log/service/v1'
window.$SupplierServiceURL = 'https://api.portal.bankflixapp.com/supplier/service/v1'
window.$TransactionServiceURL = 'https://api.portal.bankflixapp.com/transaction/service/v1'

//URL Services

const InternationalizedApp = internationalize(App)

ReactDOM.render(

    <InternationalizationProvider defaultLanguage="pt" languages={languages} dynamicImports>
        <CookiesProvider >
            <InternationalizedApp />
        </CookiesProvider>
    </InternationalizationProvider>,

    document.getElementById('root')
)

registerServiceWorker();


