import $ from 'jquery';
import React from 'react';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import RCDatePicker from '../components/datePicker';
import * as enums from '../components/enums/invoice';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import ModalSupplierSearch from '../components/supplier/modal-supplier-search';
import QueryString from 'query-string';

class InvoicesPage extends React.Component {
    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();

        this.loadNextResults = this.loadNextResults.bind(this);

        this.setValidators();

        this.setBinds();

        this.state = {
            searchText: '',

            search: {

                search: '',
                startDate: window.Moment().format('YYYY-MM-DD'),
                endDate: window.Moment().add(3, 'M').format('YYYY-MM-DD'),
                customerId: 0,
                supplierId: 0,
                invoiceNumber: '',
                status: 1
            },

            customers: [],
            suppliers: [],
            invoices: [],

            supplierSearchOpen: false,
            supplierLoading: true,

            tablePage: 0,
            tablePageSize: 10,
            tablePageCount: 0,
            numberOfResults: 0,
            currentPage: 0,
            rowCount: 0,

            loading: false,
        };
    }

    loadNextResults(page, pageSize) {

        if (pageSize === undefined) pageSize = this.state.tablePageSize;

        this.setState({ tablePage: page, tablePageSize: pageSize });
        let numberOfResults = this.state.numberOfResults;
        let numberOfRows = pageSize * (page + 1);
        let currentPage = this.state.currentPage;

        if (numberOfResults <= numberOfRows) {
            let nextPage = currentPage + 1;
            this.setState({ currentPage: nextPage });
            this.loadInvoice();
        }

        let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
        this.setState({ tablePageCount: tablePageCount });
    }

    async loadInvoice() {

        if (!this.validator.allValid())     {

            this.validator.showMessages();

            this.forceUpdate();

            return;
        }

        let resStatus = 0;

        let supplierId = this.state.search.supplierId ? this.state.search.supplierId : 0;
        let customerId = this.state.search.customerId ? this.state.search.customerId : 0;
        let status = this.state.search.status ? this.state.search.status : '';

        if (session.isCustomer()) {
            customerId = session.getCustomerId();
        }

        if (session.isSupplier()) {
            supplierId = session.getSupplierId();
        }

        let numberOfResults = this.state.numberOfResults;
        let invoices = this.state.invoices;

        this.state.search.customerId = customerId;
        this.state.search.supplierId = supplierId;
        this.state.search.status = status;
        this.state.search.page = this.state.currentPage + 1;
        this.state.search.pageSize = this.state.tablePageSize;

        this.setState({ loading: true });

        await fetch(window.$CustomerServiceURL + '/api/Invoice/ListInvoicePage/?' + QueryString.stringify(this.state.search),
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                }
            })
            .then(res => {
                resStatus = res.status;

                if (resStatus === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json()
            }).then(res => {

                let messages = {};

                switch (resStatus) {
                    case 200:

                        if (!res.data) res.data = [];

                        if (res.data)
                            res.data.result = res.data.result.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        invoices = invoices.concat(res.data.result);

                        numberOfResults = numberOfResults + (res.data.result == null ? 0 : res.data.result.length);

                        this.setState({
                            invoices: invoices,
                            numberOfResults: numberOfResults,
                            currentPage: res.data.currentPage,
                            tablePageCount: res.data.pageCount,
                            rowCount: res.data.rowCount
                        });

                        break;

                    case 409:
                        messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));

                        break;
                    case 400:
                        messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;
                    case 500:
                        break;
                }
            }).catch(err => {
                console.error(err)
            })

        this.setState({ loading: false });
    }


    setBinds() {

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.handleChangeSupplierSearch = this.handleChangeSupplierSearch.bind(this);
        this.handleReturnSupplierSearch = this.handleReturnSupplierSearch.bind(this);
        this.handleResultSupplierSearch = this.handleResultSupplierSearch.bind(this);
    }

    setValidators() {

        let _this = this;

        _this.validator = new SimpleReactValidator({

            startDateLaterThanEndDate: {

                rule: () => {

                    return !_this.state.search.startDate || !_this.state.search.endDate || window.Moment(_this.state.search.startDate, 'YYYY-MM-DD').isSameOrBefore(window.Moment(_this.state.search.endDate, 'YYYY-MM-DD'));
                }

            },
        });

    }

    async componentDidMount() {

        this.handleSearch();

        var _this = this;

        this.setState({ customerLoading: true });

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        $('.js-integer').inputmask({ greedy: false, mask: '9', repeat: '*', showMaskOnHover: false });

        await fetch(window.$CustomerServiceURL + '/api/Customer/ListActive',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();
            })
            .then(res => {

                if (res.data)
                    res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ customers: res.data });
                this.setState({ customerLoading: false });
                this.forceUpdate();
            });
    }

    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    handleChangeSupplierSearch(supplierId) {

        this.state.search.supplierId = supplierId;
        this.state.supplierSearchOpen = false;
        this.forceUpdate();
    }

    handleReturnSupplierSearch() {

        this.state.supplierSearchOpen = false;
        this.forceUpdate();
    }

    handleResultSupplierSearch(suppliers) {

        this.setState({ suppliers: suppliers, supplierLoading: false });
        this.forceUpdate();
    }

    async handleSearch() {

        this.state.invoices = [];
        this.state.currentPage = 0;
        this.state.numberOfResults = 0;
        this.state.tablePage = 0;
        this.state.tablePageSize = 10;
        this.state.tablePageCount = 0;
        this.state.rowCount = 0;

        this.setState(this.state);
        this.forceUpdate();

        this.loadInvoice();
    }

    render() {

        var fetched = this.state != null && this.state.customers != null && this.state.suppliers != null;

        if (fetched) {

            var search = this.state.searchText.toLowerCase();

            var invoiceItems = [];

            if (this.state.invoices != null) {

                var invoiceItems = this.state.invoices.filter(function (item) {

                    return item.invoiceNumber.toString().toLowerCase().indexOf(search) >= 0
                        || item.dueDate.toString().toLowerCase().indexOf(search) >= 0
                        || item.amount.toString().toLowerCase().indexOf(search) >= 0
                        || item.customerName.toString().toLowerCase().indexOf(search) >= 0
                        || item.supplierName.toString().toLowerCase().indexOf(search) >= 0
                        || item.daysExpiration.toString().toLowerCase().indexOf(search) >= 0;

                });
            }

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    {translate('invoice.invoiceTitle')}
                                    <small>{translate('invoice.invoiceTitleDescription')}</small>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.loading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">

                                <div className="m-t-10">
                                    <div className="row">

                                        <div className={session.isSupplier() ? 'col-md-6' : 'col-md-3'} style={{ display: session.isCustomer() ? 'none' : 'block' }}>
                                            <div className="m-b-5">
                                                <label htmlFor="cmbCustomer"> {translate('invoice.invoiceCustomer')} </label>

                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.customerLoading ? 'inline-block' : 'none' }}>
                                                    <div className="spinner-layer">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <RCSelect
                                                    id="cmbCustomer"
                                                    name="search.customerId"
                                                    hasEmptyOption={true}
                                                    isInt={true}
                                                    options={this.state.customers}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    placeholder={translate('invoice.invoiceCustomerPlaceHolder')}
                                                    onChange={event => { this.handleChange(event); }}
                                                    value={this.state.search.customerId}
                                                />

                                            </div>
                                        </div>

                                        <div className={session.isCustomer() ? 'col-md-6' : 'col-md-4'} style={{ display: session.isSupplier() ? 'none' : 'block' }}>
                                            <div className="m-b-5">
                                                <label htmlFor="cmbSupplier">
                                                    {translate('invoice.invoiceSupplier')}
                                                </label>

                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.supplierLoading ? 'inline-block' : 'none' }}>
                                                    <div className="spinner-layer">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <RCSelect
                                                    id="cmbSupplier"
                                                    name="search.supplierId"
                                                    hasEmptyOption={true}
                                                    isInt={true}
                                                    options={this.state.suppliers}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    placeholder={translate('invoice.invoiceSupplierPlaceHolder')}
                                                    onChange={event => { this.handleChange(event); }}
                                                    value={this.state.search.supplierId}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-md-1 m-t-20 p-l-0" style={{ display: session.isSupplier() ? 'none' : 'block' }}>
                                            <button type="button" className="btn btn-default waves-effect" onClick={() => {
                                                this.state.supplierSearchOpen = true;
                                                this.forceUpdate();
                                            }}>
                                                <i className="material-icons">&#xe8b6;</i>
                                            </button>

                                            <ModalSupplierSearch
                                                customerId={null}
                                                SearchOpen={this.state.supplierSearchOpen}
                                                handleChange={event => { this.handleChangeSupplierSearch(event); }}
                                                handleResult={event => { this.handleResultSupplierSearch(event); }}
                                                handleReturn={() => { this.handleReturnSupplierSearch(); }}
                                            />
                                        </div>

                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <label htmlFor="dtStartDate">
                                                    {translate('invoice.invoiceStartDate')}
                                                </label>

                                                <div className="form-line">
                                                    <RCDatePicker id="dtStartDate" name="search.startDate" value={this.state.search.startDate} onChange={this.handleChange} />
                                                </div>

                                                {this.validator.message('search.startDate', this.state.search.startDate, 'required|startDateLaterThanEndDate', false, { default: translate('invoice.invoiceStartDateRequired'), startDateLaterThanEndDate: translate('invoice.invoiceStartDateLaterThanEndDate') })}
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <label htmlFor="dtEndDate">
                                                    {translate('invoice.invoiceEndDate')}
                                                </label>

                                                <div className="form-line">
                                                    <RCDatePicker id="dtEndDate" name="search.endDate" value={this.state.search.endDate} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row m-t-5">

                                        <div className="col-md-3 m-t-20">
                                            <div className="input-group">
                                                <span className="input-group-addon">
                                                    <i className="material-icons">&#xe8b6;</i>
                                                </span>

                                                <div className="form-line">
                                                    <input name="searchText" className="form-control" autoComplete="off" disabled={this.state.loading} placeholder={translate('forms.searchBar')} type="text" value={this.state.searchText} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <label htmlFor="txtInvoiceNumber">
                                                    {translate('invoice.invoiceNumber')}
                                                </label>

                                                <div className="form-line">
                                                    <input id="txtInvoiceNumber" name="search.invoiceNumber" className="form-control js-change js-integer" type="text" placeholder={translate('invoice.invoiceNumberPlaceHolder')} onChange={this.handleChange} />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="m-b-5">
                                                <label htmlFor="cmbStatus">
                                                    {translate('invoice.invoiceStatus')}
                                                </label>

                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.loadingSites ? 'inline-block' : 'none' }}>
                                                    <div className="spinner-layer">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <RCSelect
                                                    id="cmbStatus"
                                                    name="search.status"
                                                    hasEmptyOption={true}
                                                    isInt={true}
                                                    options={enums.getStatus()}
                                                    optionValue="index"
                                                    optionLabel="text"
                                                    placeholder={translate('invoice.invoiceStatusPlaceHolder')}
                                                    onChange={event => { this.handleChange(event); }}
                                                    value={this.state.search.status}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 m-t-20">
                                            <button className="btn btn-block btn-primary right" disabled={this.state.loading} type="button" onClick={this.handleSearch}>
                                                <i className="material-icons">&#xe8b6;</i>
                                                <span>{translate('forms.buttonSearch')}</span>
                                            </button>
                                        </div>

                                    </div>

                                </div>

                                <ReactTable
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: translate('invoice.invoiceNumber'),
                                                    id: "invoiceNumber",
                                                    width: 110,
                                                    accessor: data => { return <span title={translate('invoice.invoiceInstallment') + " " + data.installment + " / " + data.totalInstallment}> {data.invoiceNumber}</span> }
                                                },
                                                {
                                                    Header: translate('invoice.invoiceCustomer'),
                                                    accessor: 'customerName'
                                                },
                                                {
                                                    Header: translate('invoice.invoiceSupplier'),
                                                    accessor: 'supplierName'
                                                },
                                                {
                                                    Header: translate('invoice.invoiceDueDate'),
                                                    id: "dueDate",
                                                    accessor: data => { return window.Moment(data.dueDate, 'YYYY-MM-DD').format('DD-MM-YYYY'); },
                                                    width: 105
                                                },
                                                {
                                                    Header: <span title={translate('invoice.invoiceAnticipationTitle')}> {translate('invoice.invoiceAnticipation')} </span>,
                                                    id: "transactionDate",
                                                    accessor: data => { return data.transactionDate ? window.Moment(data.transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "-"; },
                                                    width: 110
                                                },
                                                {
                                                    Header: <span title={translate('invoice.invoiceDaysExpirationTitle')}> {translate('invoice.invoiceDaysExpiration')} </span>,
                                                    id: "daysExpiration",
                                                    accessor: data => { return data.daysExpiration <= 0 ? "-" : data.daysExpiration; },
                                                    width: 125
                                                },
                                                {
                                                    Header: translate('invoice.invoiceAmount'),
                                                    id: "amount",
                                                    accessor: data => { return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.amount); },
                                                    width: 140,
                                                },
                                                {
                                                    Header: translate('invoice.invoiceStatus'),
                                                    id: "status",
                                                    accessor: data => { return <span title={enums.getStatus().find(x => { return x.index === parseInt(data.status); }).text}> {enums.getStatus().find(x => { return x.index === parseInt(data.status); }).text} </span> }
                                                }
                                            ]
                                        }
                                    ]}
                                    data={invoiceItems}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    loading={this.state.loading}
                                    previousText={translate('forms.previousText')}
                                    nextText={translate('forms.nextText')}
                                    noDataText={translate('forms.noDataText')}
                                    pageText={translate('forms.pageText')}
                                    ofText={translate('forms.ofText')}
                                    rowsText={translate('forms.rowsText')}

                                    pages={this.state.tablePageCount}
                                    page={this.state.tablePage}
                                    pageSize={this.state.tablePageSize}
                                    onPageSizeChange={(pageSize, page) => this.loadNextResults(page, pageSize)}
                                    onPageChange={(page) => this.loadNextResults(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default InvoicesPage;