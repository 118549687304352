import React from 'react';
import Modal from 'react-responsive-modal';
import { translate } from 'react-internationalization';
import * as session from '../SessionValidator';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import * as enums from '../enums/contract';
import * as enumUser from '../enums/user';
import RCSelect from '../select';
import ParticipantComponent from './participantComponent';
import PaymentArrangementComponent from './paymentArrangementComponent';
import RCDatePicker from '../datePicker';
import { cpf_cnpj_Mask } from '../mask';
import { cnpj, cpf } from '../cpf-cnpj-validator';
import SimpleReactValidator from 'simple-react-validator';

export default class ModalContractFilter extends React.Component {

    constructor(props) {

        super(props);

        this.token = session.getToken();
        this.setBinds();
        this.setValidators();
        
        this.state = {
            participantId: 0,
            participants: [],
            participantGrid: [],
            selectedPayments: [],
            tabNumber: 0,

            contractFilter: {
                id: 0,
                cardContractId: 0,
                paymentDocumentTitular: "",
                paymentDocumentTitularType: 0,
                paymentBankAccountType: "",
                paymentISPB: "",
                paymentBankNumber: "",
                paymentAgencyNumber: "",
                paymentAccountNumber: "",
                documentFinalUserReceiver: "",
                documentFinalUserReceiverType: 0,
                documentTitular: "",
                documentTitularType: "",
                divisionRules: 0,
                distributionType: "",
                value: 0,
                startDate: "",
                endDate: "",
                protocol: "",
                status: 0,
                statusErrors: "",
                createDate: window.Moment().format('YYYY-MM-DD'),
                contractFilterParticipant: [],
                contractFilterPaymentArrangement: []
            } 
        };
    }

    handlechangeMask(event) {
        event.target.value = cpf_cnpj_Mask(event.target.value);

        this.handleChange(event);
    }

    setValidators() {

        this.validator = new SimpleReactValidator({

            documentType: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },

            paymentDocumentTitular: {

                rule: (value) => {

                    let documentType = this.state.contractFilter.paymentDocumentTitularType;

                    if (documentType == 0)//cnpj
                    {
                        return cnpj.isValid(value);
                    }
                    else if (documentType == 1) {//cpf
                        return cpf.isValid(value);
                    }
                    else {
                        return false;
                    }
                }
            },

            documentFinalUserReceiver: {

                rule: (value) => {

                    let documentType1 = this.state.contractFilter.documentFinalUserReceiverType;

                    if (documentType1 == 0)//cnpj
                    {
                        return cnpj.isValid(value);
                    }
                    else if (documentType1 == 1) {//cpf
                        return cpf.isValid(value);
                    }
                    else {
                        return false;
                    }
                }
            },

            documentTitular: {

                rule: (value) => {

                    let documentType = this.state.contractFilter.documentTitularType;

                    if (documentType == 0)//cnpj
                    {
                        return cnpj.isValid(value);
                    }
                    else if (documentType == 1) {//cpf
                        return cpf.isValid(value);
                    }
                    else {
                        return false;
                    }
                }
            },
        });
    }

    async componentDidMount() {
        let _this = this;

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.setValue(event); });
        $('.js-integer').inputmask({ greedy: false, mask: '9', repeat: '*', showMaskOnHover: false });

            this.setState({
                contractFilterId: this.props.contractFilterId
            });
        this.forceUpdate();
        this.loadData();
    }

    loadData() {

        this.setState({
            loading: true
        });

        if (this.props.contractFilterId)
        {
            var contractFilter = this.state.contractFilter;
            var participantGrid = this.state.participantGrid;
            var contractFilterParticipant = {};

            fetch(window.$TransactionServiceURL + '/api/ContractFilter/' + this.props.contractFilterId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                switch (res.code) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (res.code) {

                    case 200:

                        contractFilter = res.data;

                        contractFilterParticipant = contractFilter.contractFilterParticipant;

                        var len = contractFilter.contractFilterParticipant.length;

                        for (var i = 0; i < len; i++)
                        {
                            participantGrid.push(contractFilterParticipant[i].participant);
                        }

                        var selectedPayments = contractFilter.contractFilterPaymentArrangement;

                        contractFilter.contractFilterPaymentArrangement = [];

                        this.setState({ contractFilter: contractFilter,
                            selectAll: 0,
                            participantGrid: participantGrid,
                            selectedPayments: selectedPayments,
                            loading: false,
                        });
                        
                        this.forceUpdate();
                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
        } 
        else {
            this.setState({
                selectedPayments: [],
                loading: false
            });
        }
    }

    setBinds() {
        this.handleAddParticipant = this.handleAddParticipant.bind(this);
        this.deleteParticipant = this.deleteParticipant.bind(this);
        this.resultPayments = this.resultPayments.bind(this);
        this.handlechangeMask = this.handlechangeMask.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setValue(event) {

        var target = this.state, childs = event.target.name.split('.');

        childs.forEach(function (child) {

            if (typeof target[child] === 'object') {
                target = target[child];

            } else {

                target[child] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            }
        });

        this.setState(this.state);
    }

    handleAddParticipant(participantSelected) {
        var list = this.state.contractFilter.contractFilterParticipant;

        var contractFilterParticipant = {
            id: 0,
            contractFilterId: this.state.contractFilter.id,
            participantId: participantSelected.id,
        }

        list.push(contractFilterParticipant);

        this.setState({
            contractFilterParticipant: list
        });
    }

    deleteParticipant(rowIndex) {
        var list = this.state.participants;

        list.splice(rowIndex, 1);

        this.setState({
            participants: list
        });
    }
    
    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') 
                {
                     value = event.target.checked; 
                }
                else {
                    value = event.target.value; 
                }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    handleNextTab(tabNumber) {

        this.setState({
            tabNumber: tabNumber
        });

        this.forceUpdate();
    }

    resultPayments(selectedPayments) {

        this.setState({
            selectedPayments: selectedPayments
        })
    }

    handleSubmit() {

        this.setState({
            loading: true
        });

        var contractFilter = this.state.contractFilter;
        var selectedPayments = this.state.selectedPayments;

        for (var i = 0; i < selectedPayments.length; i++)
        {
            var contractFilterPaymentArrangement = {
                id: 0,
                contractFilterId: this.state.contractFilter.id,
                paymentArrangementId: selectedPayments[i].id,
            }
            contractFilter.contractFilterPaymentArrangement.push(contractFilterPaymentArrangement);
        }

        contractFilter.cardContractId = this.props.cardContractId;

        var method = this.state.contractFilter.id != 0 ? 'Update' : 'Create';

        fetch(window.$TransactionServiceURL + '/api/contractFilter/' + method ,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(contractFilter),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                switch (res.code) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (res.code) {

                    case 200:
                        this.setState({ loading: false });
                        
                        this.forceUpdate();

                        this.props.handleReturn();

                        if (method === 'Create')
                            contractFilter = res.data;
                            this.props.addContractFilter(contractFilter);

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.props.openFilter}
                    closeOnEsc={true}
                    onClose={() => { }}
                    onEntered={() => { this.forceUpdate(); }}
                    closeIconSize={0}
                    center={true}
                    styles={{ modal: { maxWidth: '80%' } }}
                >
                    <div style={{ width: 9999 }}></div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h4>
                                            {translate('contractFilter.filterNew')}
                                        </h4>

                                        <div className="header-dropdown">
                                            <div className="preloader pl-size-xs" style={{ display: this.state.loading ? 'block' : 'none' }}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>

                                                    <div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="body">
                                        <div id="wizard_horizontal" role="application" className="wizard clearfix">
                                            <div className="steps clearfix">
                                                <ul className="nav nav-tabs tab-nav-right" role="tablist">
                                                    <li role="presentation" className={this.state.tabNumber === 0 ? "active" : ""} aria-disabled="false" aria-selected={this.state.tabNumber === 0}>
                                                        <a id="wizard_horizontal-t-0" href="#dataPayments" onClick={() => { this.handleNextTab(0) }} aria-controls="wizard_horizontal-p-0" data-toggle="tab">
                                                            <span className="current-info audible">current step: </span>
                                                            <span className="number">1.</span>
                                                            {translate('contractFilter.filterDataPayment')}
                                                        </a>
                                                    </li>
                                                    <li role="presentation" className={this.state.tabNumber === 1 ? "active" : ""} aria-disabled="true" aria-selected={this.state.tabNumber === 1}>
                                                        <a id="wizard_horizontal-t-1" href="#addParticipant" onClick={() => { this.handleNextTab(1) }} aria-controls="wizard_horizontal-p-1" data-toggle="tab">
                                                            <span className="number">2.</span>
                                                            {translate('contractFilter.participant')}
                                                        </a>
                                                    </li>
                                                    <li role="presentation" className={this.state.tabNumber === 2 ? "active" : ""} aria-disabled="true" aria-selected={this.state.tabNumber === 2}>
                                                        <a id="wizard_horizontal-t-1" href="#addPaymentArrangement" onClick={() => { this.handleNextTab(2) }} aria-controls="wizard_horizontal-p-1" data-toggle="tab">
                                                            <span className="number">2.</span>
                                                            {translate('contractFilter.paymentArrangement')}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div id="dataPayments" className="tab-pane fade active in" role="tabpanel">
                                                { this.state.tabNumber === 0 &&
                                                    <div>  
                                                        <div className="row clearfix">
                                                            <div className="col-md-4">
                                                                <h4>
                                                                                        
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className="row clearfix">
                                                            <div className="col-md-4">
                                                                <div className="input-group">
                                                                    <label className="validationRequired" htmlFor="txtTitularName">{translate('contractFilter.filterHolderName')}</label>
                                                                    <div className="form-line">
                                                                        <input id="txtTitularName" name="contractFilter.paymentTitularName" className="form-control js-change" value={this.state.contractFilter.paymentTitularName} onChange={this.handleChange} placeholder={translate('contractFilter.filterHolderName')} type="text" />

                                                                        {this.validator.message('contractFilter.paymentTitularName', this.state.contractFilter.paymentTitularName, 'required', false, { default: translate('contractFilter.filterHolderNameRequired') } )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="cmbDocumentTypeHolder" className="validationRequired">
                                                                        {translate('contractFilter.filterDocumentType')}
                                                                    </label>

                                                                    <RCSelect
                                                                        id="cmbDocumentTypeHolder"
                                                                        name="contractFilter.paymentDocumentTitularType"
                                                                        hasEmptyOption={true}
                                                                        isInt={true}
                                                                        options={enumUser.getDocumentType()}
                                                                        optionValue="index"
                                                                        optionLabel="text"
                                                                        placeholder={translate('supplier.supplierDocumentTypePlaceHolder')}
                                                                        onChange={event => { this.handleChange(event); }}
                                                                        value={this.state.contractFilter.paymentDocumentTitularType}
                                                                    />

                                                                    {this.validator.message('contractFilter.paymentDocumentTitularType', this.state.contractFilter.paymentDocumentTitularType, 'required|documentType', false, { default: translate('contract.contractDocumentTypeRequired') })}


                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="m-b-5">
                                                                    <label htmlFor="txtHoldDocument">
                                                                        {translate('contractFilter.filterHolderDocument')}
                                                                    </label>
                                                                    <div className="input-group">
                                                                        <div className="form-line">
                                                                            <input id="txtHoldDocument" name="contractFilter.paymentDocumentTitular" maxLength='19' value={this.state.contractFilter.paymentDocumentTitular} onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('contract.contractHoldDocumentPlaceHolder')} type="text" />
                                                                        </div>

                                                                        {this.validator.message('contractFilter.paymentDocumentTitular', this.state.contractFilter.paymentDocumentTitular, 'required|paymentDocumentTitular', false, { default: translate('contract.contractDocumentRequired') }, { default: translate('contract.contractDocumentInvalid') } )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className='row clearfix'> 

                                                            <div className="col-md-3">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="cmbAccountType" className="validationRequired">
                                                                        {translate('contractFilter.filterAccountType')}
                                                                    </label>

                                                                    <RCSelect
                                                                        id="cmbAccountType"
                                                                        name="contractFilter.paymentBankAccountType"
                                                                        hasEmptyOption={true}
                                                                        isInt={true}
                                                                        options={enums.getAccountType()}
                                                                        optionValue="index"
                                                                        optionLabel="text"
                                                                        placeholder={translate('contractFilter.filterAccountTypePlaceHolder')}
                                                                        onChange={event => { this.handleChange(event); }}
                                                                        value={this.state.contractFilter.paymentBankAccountType}
                                                                    />

                                                                    {this.validator.message('contractFilter.paymentBankAccountType', this.state.contractFilter.paymentBankAccountType, 'required', false, { default: translate('contractFilter.filterAccountTypeRequired') })}

                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="txtPaymentISPB" className="validationRequired">
                                                                        {translate('contractFilter.filterISPB_COMPRE')}
                                                                    </label>

                                                                    <div className="input-group">
                                                                        <div className="form-line">
                                                                            <input id="txtPaymentISPB" name="contractFilter.paymentISPB" maxLength='8' value={this.state.contractFilter.paymentISPB} onChange={this.handleChange} className="form-control js-change" autoComplete="off" placeholder={translate('contract.contractHoldDocumentPlaceHolder')} type="text" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="m-b-5">
                                                                    <label htmlFor="txtAgency">
                                                                        {translate('contractFilter.filterAgency')}
                                                                    </label>

                                                                    <div className="input-group">
                                                                        <div className="form-line">
                                                                            <input id="txtAgency" name="contractFilter.paymentAgencyNumber" maxLength='19' value={this.state.contractFilter.paymentAgencyNumber} onChange={this.handleChange} className="form-control js-change" autoComplete="off" placeholder={translate('contractFilter.filterAgencyPlaceHolder')} type="text" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-3">
                                                                <div className="m-b-5">
                                                                    <label htmlFor="txtAccountNumber">
                                                                        {translate('contractFilter.filterAccountNumber')}
                                                                    </label>
                                                                    <div className="input-group">
                                                                        <div className="form-line">
                                                                            <input id="txtAccountNumber" name="contractFilter.paymentAccountNumber" maxLength='19' value={this.state.contractFilter.paymentAccountNumber} onChange={this.handleChange} className="form-control js-change" autoComplete="off" placeholder={translate('contractFilter.filterAccountNumber')} type="text" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row clearfix">
                                                            <div className="col-md-4">
                                                                <h4>
                                                                    {translate('contractFilter.filterWarrantyDetails')}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className='row clearfix'>
                                                            
                                                            <div className="col-md-3">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="cmbDocumentFinalUserReceiverType" className="validationRequired">
                                                                        {translate('contractFilter.filterDocumentType')}
                                                                    </label>

                                                                    <RCSelect
                                                                        id="cmbDocumentFinalUserReceiverType"
                                                                        name="contractFilter.documentFinalUserReceiverType"
                                                                        hasEmptyOption={true}
                                                                        isInt={true}
                                                                        options={enumUser.getDocumentType()}
                                                                        optionValue="index"
                                                                        optionLabel="text"
                                                                        placeholder={translate('contractFilter.filterDocumentTypePlaceHolder')}
                                                                        onChange={event => { this.handleChange(event); }}
                                                                        value={this.state.contractFilter.documentFinalUserReceiverType}
                                                                    />
                                                                    {this.validator.message('contractFilter.documentFinalUserReceiverType', this.state.contractFilter.documentFinalUserReceiverType, 'required|documentType', false, { default: translate('contract.contractDocumentTypeRequired') })}


                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="input-group">
                                                                    <label htmlFor="txtFinalUserDocument" className="validationRequired">{translate('contractFilter.filterDocumentFinalUser')}</label>
                                                                    <div className="form-line">
                                                                        <input id="txtFinalUserDocument" name="contractFilter.documentFinalUserReceiver" maxLength='19' value={this.state.contractFilter.documentFinalUserReceiver} onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('contractFilter.filterDocumentFinalUserPlaceHolder')} type="text" />
                                                                    </div>
                                                                    {this.validator.message('contractFilter.documentFinalUserReceiver', this.state.contractFilter.documentFinalUserReceiver, 'required|documentFinalUserReceiver', false, { default: translate('contract.contractDocumentRequired') }, { default: translate('contract.contractDocumentInvalid') } )}

                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="cmbDistributionType" className="validationRequired">
                                                                        {translate('contractFilter.filterDistributionType')}
                                                                    </label>
                                                                    
                                                                    <RCSelect
                                                                        id="cmbDistributionType"
                                                                        name="contractFilter.distributionType"
                                                                        hasEmptyOption={true}
                                                                        isInt={true}
                                                                        options={enums.getDistributionType()}
                                                                        optionValue="index"
                                                                        optionLabel="text"
                                                                        placeholder={translate('contractFilter.filterDistributionTypePlaceHolder')}
                                                                        onChange={event => { this.handleChange(event); }}
                                                                        value={this.state.contractFilter.distributionType}
                                                                    />
                                                                    
                                                                    {this.validator.message('contractFilter.distributionType', this.state.contractFilter.distributionType, 'required', false, { default: translate('contractFilter.filterDistributionTypeRequired') })}

                                                                </div>
                                                            </div>

                                                          

                                                            <div className="col-md-2">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="cmbDivisionRule" className="validationRequired">
                                                                        {translate('contractFilter.filterDivisionRule')}
                                                                    </label>
                                                                    
                                                                    <RCSelect
                                                                        id="cmbDivisionRule"
                                                                        name="contractFilter.divisionRules"
                                                                        hasEmptyOption={true}
                                                                        isInt={true}
                                                                        options={enums.getDivisonRule()}
                                                                        optionValue="index"
                                                                        optionLabel="text"
                                                                        placeholder={translate('contractFilter.filterDivisionRulePlaceHolder')}
                                                                        onChange={event => { this.handleChange(event); }}
                                                                        value={this.state.contractFilter.divisionRules}
                                                                    />

                                                                    {this.validator.message('contractFilter.divisionRules', this.state.contractFilter.divisionRules, 'required', false, { default: translate('contractFilter.filterDivisionRuleRequired') })}

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='row clearfix'>

                                                            <div className="col-md-2">
                                                                <div className="m-b-20">
                                                                    <label htmlFor="cmbDocumentTitularType" className="validationRequired">
                                                                        {translate('contractFilter.filterDocumentType')}
                                                                    </label>

                                                                    <RCSelect
                                                                        id="cmbDocumentTitularType"
                                                                        name="contractFilter.documentTitularType"
                                                                        hasEmptyOption={true}
                                                                        isInt={true}
                                                                        options={enumUser.getDocumentType()}
                                                                        optionValue="index"
                                                                        optionLabel="text"
                                                                        placeholder={translate('contractFilter.filterDocumentTypePlaceHolder')}
                                                                        onChange={event => { this.handleChange(event); }}
                                                                        value={this.state.contractFilter.documentTitularType}
                                                                    />

                                                                    {this.validator.message('contractFilter.documentTitularType', this.state.contractFilter.documentTitularType, 'required|documentType', false, { default: translate('contract.contractDocumentTypeRequired') })}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="input-group">
                                                                    <label className="validationRequired" htmlFor="txtTitularDocument">{translate('contractFilter.filterHolder')}</label>
                                                                    <div className="form-line">
                                                                        <input id="txtTitularDocument" name="contractFilter.documentTitular" className="form-control js-change" value={this.state.contractFilter.documentTitular} onChange={this.handlechangeMask} placeholder={translate('contractFilter.filterHolder')} type="text" />
                                                                    </div>

                                                                    {this.validator.message('contractFilter.documentTitular', this.state.contractFilter.documentTitular, 'required|documentTitular', false, { default: translate('contract.contractDocumentRequired') }, { default: translate('contract.contractDocumentInvalid') } )}
                                                                </div>
                                                            </div>
                                                            

                                                            <div className="col-md-3">
                                                                <div className="input-group">
                                                                    <label htmlFor="dtStartDate">
                                                                        {translate('contractFilter.startDate')}
                                                                    </label>

                                                                    <div className="form-line">
                                                                        <RCDatePicker id="dtStartDate" name="contractFilter.startDate" value={this.state.contractFilter.startDate} onChange={this.handleChange} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="input-group">
                                                                    <label htmlFor="dtEndDate">
                                                                        {translate('contractFilter.endDate')}
                                                                    </label>

                                                                    <div className="form-line">
                                                                        <RCDatePicker id="dtEndDate" name="contractFilter.endDate" value={this.state.contractFilter.endDate} onChange={this.handleChange} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div id="addParticipant" className="tab-pane fade" role="tabpanel" >
                                                { this.state.tabNumber === 1 && 
                                                    <ParticipantComponent 
                                                        handleAddParticipant={event => { this.handleAddParticipant(event); }}
                                                        deleteParticipant={event => { this.deleteParticipant(event); }}
                                                        participantGrid={this.state.participantGrid ? this.state.participantGrid : []}
                                                    />
                                                }    
                                            </div>

                                            <div id="addPaymentArrangement" className="tab-pane fade" role="tabpanel" >
                                                
                                                { this.state.tabNumber === 2 && 
                                                    <PaymentArrangementComponent 
                                                        selectedPayments={this.state.selectedPayments}
                                                        resultPayments={event => { this.resultPayments(event); }}
                                                    />
                                                }    
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="row clearfix">
                                    <div className="col-md-11 formActions">

                                        <button className="btn btn-default waves-effect" role="menuitem" onClick={() => { this.props.handleReturn() }} data-toggle="tab">
                                            <i className="material-icons">&#xe314;</i>
                                            <span>{translate('forms.buttonReturn')}</span>
                                        </button>

                                        <button type="button" className="btn btn-success waves-effect" onClick={() => { this.handleSubmit(); }} disabled={this.state.loading}>
                                            <i className="material-icons">&#xe161;</i>
                                                <span>{translate('contract.contractSave')}</span>
                                        </button>

                                    </div>

                                    <div className="col-md-1 formActions">
                                        <div className="header-dropdown" style={{ float: 'right' }}>
                                            <div className="preloader pl-size-xs" style={{ display: this.state.loading ? 'block' : 'none' }}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                    <div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>

                    <ToastContainer hideProgressBar />
                </div>
            )
    }
}
