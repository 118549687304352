import { translate } from 'react-internationalization';
import { confirmAlert } from 'react-confirm-alert';

export function validateSession() {

    var auth = localStorage.getItem("authp");

    if (!auth) {

        confirmAlert({

            title: translate('sessionValidator.SessionExpired'),
            message: translate('sessionValidator.SessionExpiredMessage'),
            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        window.location.href = "/logout";
                    }
                }
            ],
            closeOnClickOutside: false,
            closeOnEscape: false
        });
    }
}

export function sessionExpired() {

    if (!localStorage.getItem('loginExpired')) {

        localStorage.setItem('loginExpired', true);

        confirmAlert({

            title: translate('sessionValidator.SessionExpired'),
            message: translate('sessionValidator.SessionExpiredMessage'),
            buttons: [
                {
                    label: translate('forms.buttonOk'),
                    onClick: () => {

                        localStorage.removeItem('loginExpired');
                        window.location.href = "/logout";
                    }
                }
            ],
            closeOnClickOutside: false,
            closeOnEscape: false
        });
    }
}

export function getToken() {

    return localStorage.getItem("authp");
}

export function userId() {

    return localStorage.getItem('userId');
}

export function isAdmin() {

    return (localStorage.getItem('ia') === 'true');
}

export function isUseTerm() {

    return (localStorage.getItem('ut') === 'true');
}

export function isOperator() {

    return (localStorage.getItem('io') === 'true');
}

export function isCustomer() {

    return (localStorage.getItem('t') === '1');
}

export function isSupplier() {

    return (localStorage.getItem('t') === '2');
}

export function isBank() {

    return (localStorage.getItem('t') === '3');
}

export function getCustomerId() {

    return localStorage.getItem('customerId');
}

export function getSupplierId() {

    return localStorage.getItem('supplierId');
}

export function getBankId() {

    return localStorage.getItem('bankId');
}

export function accessDenied() {

    confirmAlert({

        title: translate('accessValidator.AccessDenied'),
        message: translate('accessValidator.AccessDeniedMessage'),
        buttons: [
            {
                label: translate('forms.buttonOk'),
                onClick: () => {

                    window.location.href = "/Home";
                }
            }
        ],
        closeOnClickOutside: false,
        closeOnEscape: false
    });
}

export function auth(listPermission) {

    if (listPermission === undefined) {
        listPermission = [];
    }

    let roles = JSON.parse(localStorage.getItem('r'));

    if (!roles)
        return false;

    if (localStorage.getItem('ia') === 'true')
        return true;

    let permissions = roles.filter(function (item) {
        return listPermission.find(p => { return p.type == item.type && p.value == item.value; });
    })

    return permissions.length == listPermission.length;
}

export function userLocked() {

    confirmAlert({

        title: translate('accessValidator.UserLocked'),
        message: translate('accessValidator.UserLockedMessage'),
        buttons: [
            {
                label: translate('forms.buttonOk'),
                onClick: () => {

                }
            }
        ]
    });
}