import $ from 'jquery';
import React from 'react';
import { translate } from 'react-internationalization';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import RCDatePicker from '../components/datePicker';
import * as enums from '../components/enums/contract';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import ModalOptInSearch from '../components/supplier/modal-optIn-search';
import QueryString from 'query-string';
import { confirmAlert } from 'react-confirm-alert';
import { cpf_cnpj_Mask } from '../components/mask';

class contractsPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();

        this.setValidators();

        this.setBinds();

        this.state = {
            searchText: '',

            search: {
                search: '',
                signatureDate: window.Moment().format('YYYY-MM-DD'),
                dueDate: window.Moment().add(1, 'M').format('YYYY-MM-DD'),
                documentContractor: '',
                documentHolder: '',
                status: ""
            },

            participants: [],
            loading: false,
            query: {},

            tablePage: 0,
            tablePageSize: 10,
            tablePageCount: 0,
            numberOfResults: 0,
            currentPage: 0,
            rowCount: 0,

            contractList: [],
        };
    }

    setBinds() {

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDeleteContract = this.handleDeleteContract.bind(this);
        this.loadNextResults = this.loadNextResults.bind(this);
        this.handlechangeMask = this.handlechangeMask.bind(this);
    }

    loadNextResults(page, pageSize) {

        if (pageSize === undefined) pageSize = this.state.tablePageSize;

        this.setState({ tablePage: page, tablePageSize: pageSize });
        let numberOfResults = this.state.numberOfResults;
        let numberOfRows = pageSize * (page + 1);
        let currentPage = this.state.currentPage;

        if (numberOfResults <= numberOfRows) {
            let nextPage = currentPage + 1;
            this.setState({ currentPage: nextPage });
            this.loadContracts();
        }

        let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
        this.setState({ tablePageCount: tablePageCount });
    }

    setValidators() {

        let _this = this;

        _this.validator = new SimpleReactValidator({

            signatureDateLaterThandueDate: {

                rule: () => {

                    return !_this.state.search.signatureDate || !_this.state.search.dueDate || window.Moment(_this.state.search.signatureDate, 'YYYY-MM-DD').isSameOrBefore(window.Moment(_this.state.search.dueDate, 'YYYY-MM-DD'));
                }
            }
        });
    }

    loadContracts() {

        this.setState({
            loading: true
        });

        let documentContractor = this.state.search.documentContractor ? this.state.search.documentContractor : null;
        let documentHolder = this.state.search.documentHolder ? this.state.search.documentHolder : null;

        let numberOfResults = this.state.numberOfResults;
        let contractList = this.state.contractList;

        this.state.query.signatureDate = this.state.search.signatureDate;
        this.state.query.dueDate = this.state.search.dueDate;
        this.state.query.documentContractor = documentContractor;
        this.state.query.documentHolder = documentHolder;
        this.state.query.page = this.state.currentPage + 1;
        this.state.query.pageSize = this.state.tablePageSize;

        fetch(window.$TransactionServiceURL + '/api/CardContract/List/?' + QueryString.stringify(this.state.query),
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                switch (res.code) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (res.code) {

                    case 200:

                        if (!res.data) res.data = [];

                        if (res.data)
                            res.data.result = res.data.result.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        contractList = contractList.concat(res.data.result);

                        numberOfResults = numberOfResults + (res.data.result == null ? 0 : res.data.result.length);

                        this.setState({
                            contractList: contractList,
                            loading: false,
                            numberOfResults: numberOfResults, currentPage: res.data.currentPage,
                            tablePageCount: res.data.pageCount, rowCount: res.data.rowCount
                        });

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    async componentDidMount() {

        var _this = this;

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        this.loadContracts();
    }

    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') {
                    value = event.target.checked;
                }
                else {
                    value = event.target.value;
                }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    async handleSearch() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();

            this.forceUpdate();

            return;
        }

        this.state.contractList = [];
        this.state.currentPage = 0;
        this.state.numberOfResults = 0;
        this.state.tablePage = 0;
        this.state.tablePageSize = 10;
        this.state.tablePageCount = 0;
        this.state.rowCount = 0;

        this.setState(this.state);
        this.forceUpdate();

        this.loadContracts();
    }

    deleteContract(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        let contract = JSON.parse(JSON.stringify(this.state.contractList[rowIndex]));

                        var contractId = contract.id;

                        this.handleDeleteContract(contractId, rowIndex);
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('contract.contractDeleteTitle'),
            title: translate('contract.contractDeleteQuestion')
        });
    }

    handleDeleteContract(contractId, rowIndex) {

        var status = 0;

        this.setState({ loading: true });

        fetch(window.$TransactionServiceURL + '/api/cardcontract/Delete/' + contractId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                status = res.status;
                this.setState({ loading: false });

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (status) {

                    case 200:

                        var items = this.state.contractList;
                        items.splice(rowIndex, 1);

                        this.setState({
                            contractList: items
                        });

                        this.forceUpdate();

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });
    }

    handlechangeMask(event) {
        event.target.value = cpf_cnpj_Mask(event.target.value);

        this.handleChange(event);
    }

    render() {

        var search = this.state.searchText.toLowerCase();

        var contractList = [];

        if (this.state.contractList != null) {

            var contractList = this.state.contractList.filter(function (item) {

                return item.documentContractor.toString().toLowerCase().indexOf(search) >= 0
                    || item.documentHolder.toString().toLowerCase().indexOf(search) >= 0
            });
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                {translate('contract.contractTitle')}
                                <small>{translate('contract.contractList')}</small>
                            </h2>

                            <div className="header-dropdown">
                                <div className="preloader pl-size-xs" style={{ display: this.state.loading ? 'block' : 'none' }}>
                                    <div className="spinner-layer">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>

                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="body">
                            <div className="m-t-10">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="m-b-5">
                                            <label htmlFor="txtDocument">
                                                {translate('contract.contractContractorDocument')}
                                            </label>

                                            <div className="input-group">
                                                <div className="form-line">
                                                    <input id="txtDocument" name="search.documentContractor" maxLength='19' value={this.state.search.documentContractor} onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('contract.contractContractorDocumentPlaceHolder')} type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="m-b-5">
                                            <label htmlFor="cmbSupplier">
                                                {translate('contractFilter.filterHolderDocument')}
                                            </label>

                                            <div className="input-group">
                                                <div className="form-line">
                                                    <input id="txtDocument" name="search.documentHolder" maxLength='19' value={this.state.search.documentHolder} onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('contract.contractHoldDocumentPlaceHolder')} type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="input-group">
                                            <label htmlFor="contractRegisterDate">
                                                {translate('contract.contractRegisterDate')}
                                            </label>
                                            <div className="form-line">
                                                <RCDatePicker id="contractRegisterDate" name="search.signatureDate" value={this.state.search.signatureDate} onChange={this.handleChange} />
                                            </div>

                                            {this.validator.message('search.signatureDate', this.state.search.signatureDate, 'required', false, { default: translate('contract.contractRegisterDateRequired') })}
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="input-group">
                                            <label htmlFor="dtDueDate">
                                                {translate('contract.contractDueDate')}
                                            </label>
                                            <div className="form-line">
                                                <RCDatePicker id="dtDueDate" name="search.dueDate" value={this.state.search.dueDate} onChange={this.handleChange} />
                                            </div>

                                            {this.validator.message('search.dueDate', this.state.search.dueDate, 'required', false, { default: translate('contract.contractDueDateRequired') })}
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-t-5">
                                    <div className="col-md-3 m-t-20">
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="material-icons">&#xe8b6;</i>
                                            </span>
                                            <div className="form-line">
                                                <input name="searchText" className="form-control" autoComplete="off" disabled={this.state.loading} placeholder={translate('forms.searchBar')} type="text" value={this.state.searchText} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="m-b-5">
                                            <label htmlFor="cmbStatus">
                                                {translate('documents.documentStatus')}
                                            </label>

                                            <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.loading ? 'inline-block' : 'none' }}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <RCSelect
                                                id="cmbStatus"
                                                name="search.status"
                                                hasEmptyOption={true}
                                                isInt={true}
                                                options={enums.getCardContractStatus()}
                                                optionValue="index"
                                                optionLabel="text"
                                                placeholder={translate('documents.documentStatusPlaceHolder')}
                                                onChange={event => { this.handleChange(event); }}
                                                value={this.state.search.status}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 m-t-20">
                                        <button className="btn btn-block btn-primary right" disabled={this.state.loading} type="button" onClick={this.handleSearch}>
                                            <i className="material-icons">&#xe8b6;</i>
                                            <span>{translate('forms.buttonSearch')}</span>
                                        </button>
                                    </div>
                                    <div className="col-md-3 m-t-20">
                                        <Link to={"/contract"}>
                                            <button type="button" className="btn btn-block btn-primary right" disabled={this.state.loading}>
                                                <i className="material-icons">&#xe145;</i>
                                                <span>{translate('contract.contractAddNew')}</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <ReactTable
                                data={this.state.contractList}
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "ID",
                                                id: "contractId",
                                                maxWidth: 60,
                                                accessor: data => { return <span title={data.id}> {data.id} </span> }
                                            },
                                            {
                                                Header: translate('contract.contractContractor'),
                                                id: "documentContractor",
                                                accessor: data => { return <span title={data.documentContractor}> {data.documentContractor} </span> }
                                            },
                                            {
                                                Header: translate('contract.contractHolder'),
                                                id: "documentHolder",
                                                accessor: data => { return <span title={data.documentHolder}> {data.documentHolder} </span> }
                                            },
                                            {
                                                Header: translate('contract.contractDueDate'),
                                                id: "dueDate",
                                                accessor: data => { return window.Moment(data.dueDate, 'YYYY-MM-DD').format('DD-MM-YYYY'); },
                                            },
                                            {
                                                Header: translate('contract.contractEffect'),
                                                id: "typeEffect",
                                                accessor: data => { return <span title={enums.getTypeEffect().find(x => { return x.index === parseInt(data.typeEffect); }).text}> {enums.getTypeEffect().find(x => { return x.index === parseInt(data.typeEffect); }).text} </span> }
                                            },
                                            {
                                                Header: translate('contract.contractRegister'),
                                                id: "signatureDate",
                                                accessor: data => { return window.Moment(data.signatureDate, 'YYYY-MM-DD').format('DD-MM-YYYY'); },
                                            },
                                            {
                                                Header: translate('contract.contractStatus'),
                                                id: "contractStatus",
                                                accessor: data => { return <span title={data.contractStatus}> {data.contractStatus} </span> }
                                            },
                                            {
                                                Cell: row => (
                                                    <div className="align-center">
                                                        <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteContract(row.index) }} disabled={this.state.loading} >
                                                            <i className="material-icons">&#xe872;</i>
                                                        </button>

                                                        <Link to={"/contract/" + row.original.id}>
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" title={translate('forms.buttonEdit')} disabled={this.state.loading} >
                                                                <i className="material-icons">&#xe3c9;</i>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                ),
                                                maxWidth: 150
                                            }
                                        ]
                                    }
                                ]
                                }
                                defaultPageSize={10}
                                className="-striped -highlight"
                                loading={this.state.loading}

                                previousText={translate('forms.previousText')}
                                nextText={translate('forms.nextText')}
                                noDataText={translate('forms.noDataText')}
                                pageText={translate('forms.pageText')}
                                ofText={translate('forms.ofText')}
                                rowsText={translate('forms.rowsText')}

                                pages={this.state.tablePageCount}
                                page={this.state.tablePage}
                                pageSize={this.state.tablePageSize}
                                onPageSizeChange={(pageSize, page) => this.loadNextResults(page, pageSize)}
                                onPageChange={(page) => this.loadNextResults(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default contractsPage;