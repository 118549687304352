import { translate } from 'react-internationalization';

export function getStatus() {

    let list = [

        { text: translate('enums.documentStatus.notSigned'), index: 0 },
        { text: translate('enums.documentStatus.signed'), index: 1 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}