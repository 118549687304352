import React from 'react';
import Modal from 'react-responsive-modal';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import * as session from '../SessionValidator';

export default class ModalCustomerAddress extends React.Component {

    constructor(props) {

        super(props);

        this.token = session.getToken();

        this.state = {
            addressOpen: false
        };
    }

    async componentDidMount() {

        //se passar o "customer" como parametro
        if (this.props.customer) {
            this.setState({ customer: this.props.customer });
            this.forceUpdate();
        }
        else {
            this.getCustomer();
        }
    }

    async getCustomer() {

        if (this.props.customerId > 0) {

            this.setState({ loading: true });

            await fetch(window.$CustomerServiceURL + '/api/customer/' + this.props.customerId,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    this.setState({ loading: false });

                    if (res.data) {

                        if (this.props.address != null) {

                            this.state.address = this.props.address;
                        }
                        else if (res.data.address && res.data.address.length > 0) {

                            let address = res.data.address.find(x => { return x.isMaster; });
                            this.state.address = address ? address : res.data.address[0];

                            this.props.handleChangeAddress(this.state.address);
                        }
                        else {
                            this.state.address = {
                                name: '',
                                legalName: '',
                                cnpj: '',
                                country: {},
                                city: '',
                                district: '',
                                street: '',
                                number: '',
                                complement: '',
                                zipCode: '',
                                isMaster: true,
                                phoneNumber: '',
                                email: '',
                                stateRegistration: '',
                                state: {
                                    name: ''
                                }
                            };
                        }
                    }

                    this.setState({ customer: res.data });
                    this.forceUpdate();
                });

        } else {

            let address = {
                name: '',
                legalName: '',
                cnpj: '',
                country: {},
                city: '',
                district: '',
                street: '',
                number: '',
                complement: '',
                zipCode: '',
                isMaster: true,
                phoneNumber: '',
                email: '',
                stateRegistration: '',
                state: {
                    name: ''
                }
            };

            this.setState({
                address: address,
                customer: {}
            });

            this.forceUpdate();
        }
    }

    handleEditAddress() {

        this.state.addressOpen = true;

        this.forceUpdate();
    }

    handleReturnAddress() {

        this.state.addressOpen = false;

        this.forceUpdate();
    }

    handleChangeAddress(address) {

        this.props.handleChangeAddress(address);

        this.state.address = address;
        this.forceUpdate();

        this.handleReturnAddress();
    }

    render() {

        var fetched = this.state != null && this.state.customer != null && this.state.address != null;

        if (fetched) {

            return (
                <div>

                    <div className="col-md-12">
                        <div className="m-b-5" style={{ padding: '15px', border: '1px solid #ddd' }}>
                            <div>
                                <h4>{translate('transaction.transactionCustomerAddressTitle')}</h4>
                            </div>

                            <p>
                                <b>{translate('customer.customerLegalName')}: </b>{this.state.address.legalName}
                                <b> {translate('customer.customerCNPJ')}: </b>{this.state.address.cnpj}
                            </p>

                            <p>
                                <b>{translate('customer.customerAddressState')}: </b>{this.state.address.state.name}
                                <b> {translate('customer.customerAddressCity')}: </b>{this.state.address.city}
                                <b> {translate('customer.customerAddressZipCode')}: </b>{this.state.address.zipCode}
                                <b> {translate('customer.customerAddressStreet')}: </b>{this.state.address.street}
                                <b> {translate('customer.customerAddressDistrict')}: </b>{this.state.address.district}
                                <b> {translate('customer.customerAddressNumber')}: </b>{this.state.address.number}
                            </p>

                            {
                                (session.isAdmin() || session.isOperator()) &&
                                (this.props.transactionStatus == 0 || this.props.transactionStatus == 7) &&
                                <button className="btn bg-deep-purple waves-effect" type="button" disabled={this.props.loading} onClick={() => { this.handleEditAddress(); }}>
                                    <i className="material-icons">&#xe8d5;</i>
                                    <span>{translate('transaction.transactionChangeAddress')}</span>
                                </button>
                            }

                        </div>
                    </div>

                    <Modal
                        open={this.state.addressOpen}
                        closeOnEsc={false}
                        onClose={() => { }}
                        onEntered={() => { this.forceUpdate(); }}
                        closeIconSize={0}
                        center={true}
                        styles={{ modal: { maxWidth: '80%' } }}
                    >
                        <div>
                            <div style={{ width: 9999 }}></div>

                            <div>
                                <h4>{translate('customer.customerAddressNameTitle')}</h4>
                                <hr />
                            </div>

                            <div>

                                <ReactTable
                                    data={this.state.customer.address}
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: translate('customer.customerAddressName'),
                                                    accessor: "name",
                                                },
                                                {
                                                    Header: translate('customer.customerCNPJ'),
                                                    accessor: "cnpj",
                                                },
                                                {
                                                    Header: translate('customer.customerAddressState'),
                                                    accessor: "state.name",

                                                },
                                                {
                                                    Header: translate('customer.customerAddressCity'),
                                                    accessor: "city",

                                                },
                                                {
                                                    Header: translate('customer.customerAddressDistrict'),
                                                    accessor: "district",

                                                },
                                                {
                                                    Header: translate('customer.customerAddressIsMaster'),
                                                    Cell: row => {
                                                        return <div className="align-center">

                                                            {row.original.isMaster &&
                                                                <i className="material-icons color-success">&#xe86c;</i>
                                                            }
                                                            {!row.original.isMaster &&
                                                                <i className="material-icons color-danger">&#xe5c9;</i>
                                                            }

                                                        </div>
                                                    },
                                                    maxWidth: 100
                                                }
                                            ]
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                    loading={this.props.loading}
                                    previousText={translate('forms.previousText')}
                                    nextText={translate('forms.nextText')}
                                    noDataText={translate('forms.noDataText')}
                                    pageText={translate('forms.pageText')}
                                    ofText={translate('forms.ofText')}
                                    rowsText={translate('forms.rowsText')}
                                    getTrProps={(state, row, column) => {
                                        return {
                                            onClick: () => { if (!this.props.loading) this.handleChangeAddress(row.original); },
                                            style: { cursor: 'pointer' }
                                        }
                                    }}
                                />

                            </div>

                            <div className="row m-t-20">
                                <div className="col-xs-12 align-right">

                                    <button type="button" className="btn btn-default waves-effect" disabled={this.props.loading} onClick={() => { this.handleReturnAddress(); }}>
                                        <i className="material-icons">&#xe5c4;</i>
                                        <span>{translate('forms.buttonReturn')}</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
            )
        } else {

            return (
                <div className="col-md-12">
                    <div className="m-b-5" style={{ padding: '15px', border: '1px solid #ddd' }}>
                        <div className="preloader pl-size-lg align-center">
                            <div className="spinner-layer">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>

                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
