import $ from 'jquery';
import React from 'react';
import { translate } from 'react-internationalization';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import RCDatePicker from '../components/datePicker';
import * as enums from '../components/enums/optIn';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import ModalOptInSearch from '../components/supplier/modal-optIn-search';
import QueryString from 'query-string';
import { confirmAlert } from 'react-confirm-alert';
import { cpf_cnpj_Mask } from '../components/mask';

class optInListPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();
        this.loadNextResults = this.loadNextResults.bind(this);

        this.setValidators();

        this.setBinds();

        this.state = {
            searchText: '',
            participantSearchOpen: false,

            search: {

                search: '',
                startDate: window.Moment().format('YYYY-MM-DD'),
                endDate: window.Moment().add(1, 'M').format('YYYY-MM-DD'),
                participantId: 0,
                urdocumentFinalUserReceiver: '',
                status: ""
            },

            participants: [],
            loadingSearch: true,
            loading: true,
            query: {},

            tablePage: 0,
            tablePageSize: 10,
            tablePageCount: 0,
            numberOfResults: 0,
            currentPage: 0,
            rowCount: 0,

            optInList: [],
        };
    }

    setBinds() {

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDeleteOptIn = this.handleDeleteOptIn.bind(this);

        this.handlechangeMask = this.handlechangeMask.bind(this);
    }

    loadNextResults(page, pageSize) {

        if (pageSize === undefined) pageSize = this.state.tablePageSize;

        this.setState({ tablePage: page, tablePageSize: pageSize });
        let numberOfResults = this.state.numberOfResults;
        let numberOfRows = pageSize * (page + 1);
        let currentPage = this.state.currentPage;

        if (numberOfResults <= numberOfRows) {
            let nextPage = currentPage + 1;
            this.setState({ currentPage: nextPage });
            this.loadOptIns();
        }

        let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
        this.setState({ tablePageCount: tablePageCount });
    }

    handleChangeParticipantSearch(participantId) {

        this.state.search.participantId = participantId;
        this.state.participantSearchOpen = false;
        this.forceUpdate();
    }

    handleReturnParticipantSearch() {

        this.state.participantSearchOpen = false;
        this.forceUpdate();
    }

    handleResultParticipantSearch(participants) {

        this.setState({ participants: participants, loading: false });
        this.forceUpdate();
    }
    setValidators() {

        let _this = this;

        _this.validator = new SimpleReactValidator({

            startDateLaterThanEndDate: {

                rule: () => {

                    return !_this.state.search.startDate || !_this.state.search.endDate || window.Moment(_this.state.search.startDate, 'YYYY-MM-DD').isSameOrBefore(window.Moment(_this.state.search.endDate, 'YYYY-MM-DD'));
                }
            }
        });
    }

    loadOptIns() {

        var _this = this;

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        let participantId = this.state.search.participantId ? this.state.search.participantId : 0;
        let urdocumentFinalUserReceiver = this.state.search.urdocumentFinalUserReceiver ? this.state.search.urdocumentFinalUserReceiver : null;
        let status = this.state.search.status === undefined ? '' : this.state.search.status;

        let numberOfResults = this.state.numberOfResults;
        let optInList = this.state.optInList;

        this.state.query.page = this.state.currentPage + 1;
        this.state.query.pageSize = this.state.tablePageSize;
        this.state.query.participantId = participantId;
        this.state.query.urdocumentFinalUserReceiver = urdocumentFinalUserReceiver;
        this.state.query.startDate = this.state.search.startDate;
        this.state.query.endDate = this.state.search.endDate;
        this.state.query.status = this.state.search.status;

        fetch(window.$SupplierServiceURL + '/api/OptIn/List/?' + QueryString.stringify(this.state.query),
        {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer' // no-referrer, *client
        })
        .then(res => {

            switch (res.code) {

                case 401:
                    session.sessionExpired();
                    res = [];
                    return res;

                    break;

                case 403:
                    session.accessDenied();
                    res = [];
                    return res;

                    break;

                default:
                    return res.json();
            }

        }).then(res => {

            switch (res.code) {

                case 200:

                    if (!res.data) res.data = [];

                        if (res.data)
                            res.data.result = res.data.result.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        optInList = optInList.concat(res.data.result);

                        numberOfResults = numberOfResults + (res.data.result == null ? 0 : res.data.result.length);

                        this.setState({
                            optInList: optInList,
                            loadingSearch: false,
                            numberOfResults: numberOfResults, currentPage: res.data.currentPage,
                            tablePageCount: res.data.pageCount, rowCount: res.data.rowCount
                        });

                        break;

                case 400:
                case 409:
                    var messages = res.data;
                    messages.forEach(ex => toast.warn(ex.message));
                    break;

                case 500:
                    toast.error('error');
                    break;
            }

        }).catch(err => {
            console.error(err);
        });
    }

    async componentDidMount() {

        let participants = this.state.participants;
        
        fetch(window.$SupplierServiceURL + '/api/OptIn/ParticipantList',
        {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer' // no-referrer, *client
        })
        .then(res => {

            this.setState({ paymentArrangementLoading: false });

            switch (res.code) {
                case 401:
                    session.sessionExpired();
                    res = [];
                    return res;
                    
                    break;

                case 403:
                    session.accessDenied();
                    res = [];
                    return res;

                    break;
    
                    default:
                        return res.json();
        }
        }).then(res => {
            switch (res.code) {

                    case 200:

                        if (!res.data.result) res.data.result = [];

                        if (res.data.result)
                            res.data.result = res.data.result.sort(function (a, b) { return (a.legalName < b.legalName) ? -1 : (a.legalName > b.legalName) ? 1 : 0; });

                            participants = participants.concat(res.data.result);

                        this.setState({
                            participants: participants,
                            loading: false,
                        });

                        this.forceUpdate();

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });

        this.loadOptIns();
    }

    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') 
                {
                     value = event.target.checked; 
                }
                else {
                    value = event.target.value; 
                }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    async handleSearch() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();

            this.forceUpdate();

            return;
        }

        this.state.optInList = [];
        this.state.currentPage = 0;
        this.state.numberOfResults = 0;
        this.state.tablePage = 0;
        this.state.tablePageSize = 10;
        this.state.tablePageCount = 0;
        this.state.rowCount = 0;

        this.setState(this.state);
        this.forceUpdate();

        this.loadOptIns();
    }

    deleteOptIn(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        let optIn = JSON.parse(JSON.stringify(this.state.optInList[rowIndex]));

                        var optInId = optIn.id;

                        this.handleDeleteOptIn(optInId, rowIndex);
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('optIn.optInDeleteTitle'),
            title: translate('optIn.optInDeleteQuestion')
        });
    }

    handleDeleteOptIn(optInId, rowIndex) {

        var status = 0;

        this.setState({ loading: true });

        fetch(window.$SupplierServiceURL + '/api/OptIn/Delete/' + optInId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                status = res.status;
                this.setState({ loading: false });

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (status) {

                    case 200:

                        var items = this.state.optInList;
                        items.splice(rowIndex, 1);
                        this.state.optInList = items;

                        this.forceUpdate();

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });

    }

    handlechangeMask(event) {
        event.target.value = cpf_cnpj_Mask(event.target.value);

        this.handleChange(event);
    }

    render() {

        var fetched = this.state != null && this.state.participants != null;

        if (fetched) {

            var search = this.state.searchText.toLowerCase();

            var optInList = [];

            if (this.state.optInList != null) {

                var optInList = this.state.optInList.filter(function (item) {

                    return item.documentFinancier.toString().toLowerCase().indexOf(search) >= 0
                        || item.urdocumentFinalUserReceiver.toString().toLowerCase().indexOf(search) >= 0
                });
            }
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    {translate('optIn.optInTitle')}
                                    <small>{translate('optIn.optInList')}</small>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.loading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="m-t-10">
                                    <div className="row">
                                        <div className='col-md-3'>
                                            <div className="m-b-5">

                                                <label htmlFor="cmbParticipant" className="validationRequired">
                                                    {translate('optIn.optInAcquirer')}
                                                </label>

                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.customerLoading ? 'inline-block' : 'none' }}>
                                                    <div className="spinner-layer">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <RCSelect
                                                    id="cmbParticipant"
                                                    name="search.participantId"
                                                    hasEmptyOption={true}
                                                    isInt={true}
                                                    options={this.state.participants}
                                                    optionValue="id"
                                                    optionLabel="legalName"
                                                    placeholder={translate('optIn.optInAcquirerPlaceHolder')}
                                                    onChange={event => { this.handleChange(event); }}
                                                    value={this.state.search.participantId}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-1 m-t-20 p-l-0">
                                                <button type="button" className="btn btn-default waves-effect" onClick={() => {
                                                    this.state.participantSearchOpen = true;
                                                    this.forceUpdate();
                                                    }}>
                                                        
                                                    <i className="material-icons">&#xe8b6;</i>
                                                </button>

                                                <ModalOptInSearch
                                                    SearchOpen={this.state.participantSearchOpen}
                                                    handleChange={event => { this.handleChangeParticipantSearch(event); }}
                                                    handleResult={event => { this.handleResultParticipantSearch(event); }}
                                                    handleReturn={() => { this.handleReturnParticipantSearch(); }}
                                                />
                                        </div>  

                                        <div className="col-md-3">
                                            <div className="m-b-5">
                                                <label htmlFor="cmbSupplier">
                                                    {translate('optIn.optInFinalUserCNPJ')}
                                                </label>

                                                <div className="input-group">
                                                    <div className="form-line">
                                                        <input id="txtDocument" name="search.urdocumentFinalUserReceiver" maxLength='19' value={this.state.search.urdocumentFinalUserReceiver} onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('supplier.supplierDocumentPlaceHolder')} type="text" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <label htmlFor="dtStartDate">
                                                    {translate('documents.documentStartDate')}
                                                </label>
                                                <div className="form-line">
                                                    <RCDatePicker id="dtStartDate" name="search.startDate" value={this.state.search.startDate} onChange={this.handleChange} />
                                                </div>

                                                {this.validator.message('search.startDate', this.state.search.startDate, 'required|startDateLaterThanEndDate', false, { default: translate('documents.documentStartDateRequired'), startDateLaterThanEndDate: translate('documents.documentStartDateLaterThanEndDate') })}
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <label htmlFor="dtEndDate">
                                                    {translate('documents.documentEndDate')}
                                                </label>
                                                <div className="form-line">
                                                    <RCDatePicker id="dtEndDate" name="search.endDate" value={this.state.search.endDate} onChange={this.handleChange} />
                                                </div>

                                                {this.validator.message('search.endDate', this.state.search.endDate, 'required', false, { default: translate('documents.documentEndDateRequired') })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row m-t-5">
                                        <div className="col-md-3 m-t-20">
                                            <div className="input-group">
                                                <span className="input-group-addon">
                                                    <i className="material-icons">&#xe8b6;</i>
                                                </span>
                                                <div className="form-line">
                                                    <input name="searchText" className="form-control" autoComplete="off" disabled={this.state.loading} placeholder={translate('forms.searchBar')} type="text" value={this.state.searchText} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="m-b-5">
                                                <label htmlFor="cmbStatus">
                                                    {translate('documents.documentStatus')}
                                                </label>

                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.loadingSites ? 'inline-block' : 'none' }}>
                                                    <div className="spinner-layer">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <RCSelect
                                                    id="cmbStatus"
                                                    name="search.status"
                                                    hasEmptyOption={true}
                                                    isInt={true}
                                                    options={enums.getStatus()}
                                                    optionValue="index"
                                                    optionLabel="text"
                                                    placeholder={translate('documents.documentStatusPlaceHolder')}
                                                    onChange={event => { this.handleChange(event); }}
                                                    value={this.state.search.status}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 m-t-20">
                                            <button className="btn btn-block btn-primary right" disabled={this.state.loadingSearch} type="button" onClick={this.handleSearch}>
                                                <i className="material-icons">&#xe8b6;</i>
                                                <span>{translate('forms.buttonSearch')}</span>
                                            </button>
                                        </div>
                                        <div className="col-md-3 m-t-20">
                                            <Link to={"/optIn"}>
                                                <button type="button" className="btn btn-block btn-primary right">
                                                    <i className="material-icons">&#xe145;</i>
                                                    <span>{translate('optIn.optInAddNew')}</span>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <ReactTable
                                    data={this.state.optInList}
                                    columns={[
                                    {
                                        columns: [
                                                    {
                                                        Header: translate('optIn.optInFinancierCNPJ'),
                                                        id: "documentFinancier",
                                                        accessor: data => { return <span title={data.documentFinancier}> {data.documentFinancier} </span> }
                                                    },
                                                    {
                                                        Header: translate('optIn.optInFinalUserCNPJ'),
                                                        id: "documentReceiver",
                                                        accessor: data => { return <span title={data.urdocumentFinalUserReceiver}> {data.urdocumentFinalUserReceiver} </span> }
                                                    },
                                                    {
                                                        Header: translate('documents.documentCreationDate'),
                                                        id: "createDate",
                                                        accessor: data => { return window.Moment(data.createDate, 'YYYY-MM-DD').format('DD-MM-YYYY'); },
                                                    },
                                                    {
                                                        Cell: row => (
                                                            <div className="align-center">
                                                                <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteOptIn(row.index) }}>
                                                                    <i className="material-icons">&#xe872;</i>
                                                                </button>
    
                                                                <Link to={ "/optIn/" + row.original.protocol}>
                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" title={translate('forms.buttonEdit')}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        ),
                                                        maxWidth: 150
                                                    }
                                                ]                                                
                                            }
                                        ]
                                    }
                                    defaultPageSize={10}
                                    className="-striped -highlight m-b-40"
                                    previousText={translate('forms.previousText')}
                                    nextText={translate('forms.nextText')}
                                    noDataText={translate('forms.noDataText')}
                                    pageText={translate('forms.pageText')}
                                    ofText={translate('forms.ofText')}
                                    rowsText={translate('forms.rowsText')}
                                    pages={this.state.tablePageCount}
                                    page={this.state.tablePage}
                                    pageSize={this.state.tablePageSize}
                                    onPageSizeChange={(pageSize, page) => this.loadNextResults(page, pageSize)}
                                    onPageChange={(page) => this.loadNextResults(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default optInListPage;