import { translate } from 'react-internationalization';

export function getTypeEffect() {

    let list = [

        { text: translate('enums.contractEffect.changeownerShip'), index: 1 },
        { text: translate('enums.contractEffect.fiduciary'), index: 2 },
        { text: translate('enums.contractEffect.burdenOrOthers'), index: 3 },
        { text: translate('enums.contractEffect.judicialBlockage'), index: 4 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getCardContractStatus() {

    let list = [

        { text: translate('enums.cardContractStatus.active'), index: 1 },
        { text: translate('enums.cardContractStatus.inactive'), index: 0 }
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getTypeService() {

    let list = [

        { text: translate('enums.contractService.collateralManagement'), index: 1 },
        { text: translate('enums.contractService.simpleRegistration'), index: 2 },
        { text: translate('enums.contractService.monitoring'), index: 3 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}


export function getModality() {

    let list = [

        { text: translate('enums.contractModality.rotary'), index: 1 },
        { text: translate('enums.contractModality.installments'), index: 2 },
        { text: translate('enums.contractModality.assignment'), index: 3 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getRecovery() {

    let list = [

        { text: translate('enums.contractRenegotiation.no'), index: 0 },
        { text: translate('enums.contractRenegotiation.yes'), index: 1 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getAccept() {

    let list = [

        { text: translate('enums.contractUnconditionalAcceptance.yes'), index: 1 },
        { text: translate('enums.contractUnconditionalAcceptance.no'), index: 2 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getIndexer() {

    let list = [

        { text: translate('enums.contractIndexer.fixedRate'), index: 1 },
        { text: translate('enums.contractIndexer.selicRate'), index: 2 },
        { text: translate('enums.contractIndexer.diRate'), index: 3 },
        { text: 'IPCA', index: 4 },
        { text: 'IGPM', index: 5 },
        { text: translate('enums.contractIndexer.dollarCurrency'), index: 6 },
        { text: translate('enums.contractIndexer.euroCurrency'), index: 7 },
        { text: translate('enums.contractIndexer.other'), index: 8 },

    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getAccountType() {

    let list = [

        { text: translate('enums.contractFilterAccountType.checkingAccount'), index: 1 },
        { text: translate('enums.contractFilterAccountType.depositAccount'), index: 2 },
        { text: translate('enums.contractFilterAccountType.escrowAccount'), index: 3 },
        { text: translate('enums.contractFilterAccountType.investmentAccount'), index: 4 },
        { text: translate('enums.contractFilterAccountType.paymentAccount'), index: 5 },
        { text: translate('enums.contractFilterAccountType.savingsAccounts'), index: 6 },

    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getDivisonRule() {

    let list = [

        { text: translate('enums.contractFilterDivisionRule.definedValue'), index: 1 },
        { text: translate('enums.contractFilterDivisionRule.percentage'), index: 2 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getDistributionType() {

    let list = [

        { text: translate('enums.contractFilterDistributionType.stacking'), index: 1 },
        { text: translate('enums.contractFilterDistributionType.stackingSmoke'), index: 2 },
        { text: translate('enums.contractFilterDistributionType.smokeFixedValue'), index: 3 },
        { text: translate('enums.contractFilterDistributionType.pro_rata'), index: 4 },
        { text: translate('enums.contractFilterDistributionType.pro_rataSmoke'), index: 5 },
        { text: translate('enums.contractFilterDistributionType.pro_rataProportional'), index: 6 },
        { text: translate('enums.contractFilterDistributionType.stackingPercentage'), index: 7 },
        { text: translate('enums.contractFilterDistributionType.stackingPercentageSmoke'), index: 8 },
        { text: translate('enums.contractFilterDistributionType.smokePercentage'), index: 9 },
        { text: translate('enums.contractFilterDistributionType.pro_rataPercentage'), index: 10 },
        { text: translate('enums.contractFilterDistributionType.pro_rataPercentageSmoke'), index: 11 },
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}