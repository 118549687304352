import React from 'react';
import { submitReportDailyLog } from "../../pages/helper.js";
import ReactTable from "react-table";
import { translate } from 'react-internationalization';
import * as session from '../SessionValidator';
import SimpleReactValidator from 'simple-react-validator';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';

const moment = window.Moment;

export default class ClassificationCustomerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.setValidators();
        this.token = session.getToken();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTypingEmail = this.handleTypingEmail.bind(this);
        this.saveClassification = this.saveClassification.bind(this);
        this.editClassification = this.editClassification.bind(this);
        this.deleteClassification = this.deleteClassification.bind(this);
        this.resetClassification = this.resetClassification.bind(this);

        this.state = {


            currentClassification: {

                rowIndex: -1,
                classification: {
                    id: 0,
                    name: '',
                    startRate: 0,
                    endRate: 0,
                    customerProfitPercentage: 50,
                    portalProfitPercentage: 50
                }
            },

            classificationList: [],
            loading: false,

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },
        };
    }

    async componentDidUpdate() {

        let _this = this;

        $('.js-customer-classification-change').each(function () { _this.setValue(this); });
        $('.js-decimal').inputmask('decimal', { digits: 4, digitsOptional: false, max: 999999999, placeholder: '0.00', rightAlign: false, showMaskOnHover: false });
    }

    async componentDidMount() {

        let _this = this;

        $('body').on('change', '.js-customer-classification-change', function (event) { _this.handleChange(event); });

        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });

        if (this.props.customerId) {
            fetch(window.$CustomerServiceURL + '/api/classification/List/' + this.props.customerId,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                    if (res.status === 404) {
                        res = [];

                        return res;

                    }

                })
                .then(res => {

                    res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                    this.setState({ classificationList: res.data });
                    this.forceUpdate();
                });
        } else {

            let currentClassification = {
                    rowIndex: -1,
                    classification: {
                            id: 0,
                            name: '',
                            startRate: 0,
                            endRate: 0,
                            customerProfitPercentage: 50,
                            portalProfitPercentage: 50
                    },
            };

            let ClassficationList = [];

            this.setState({
                currentClassification: currentClassification,
                ClassficationList: ClassficationList
            });
            this.forceUpdate();
        }
    }


    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    handleTypingEmail(e) {
        e.target.value = e.target.value.toLowerCase()
    }

    resetClassification() {

        this.state.currentClassification = {
                rowIndex: -1,
                classification: {
                    name: '',
                    startRate: 0,
                    endRate: 0,
                    customerProfitPercentage: 50,
                    portalProfitPercentage: 50
                }
        };

        if (this.classificationValidator !== undefined) {
            this.classificationValidator.hideMessages();
        }

        this.setState(this.state);
    }

    saveClassification() {

        if (!this.classificationValidator.allValid()) {

            this.classificationValidator.showMessages();
            this.forceUpdate();

            return;
        }

        this.handleSubmit();
    }

    editClassification(rowIndex) {

        let reportDailyLog = this.state.reportDailyLog;

        this.state.currentClassification.classification = JSON.parse(JSON.stringify(this.state.classificationList[rowIndex]));
        this.state.currentClassification.rowIndex = rowIndex;

        reportDailyLog.objectBefore = JSON.stringify(this.state.currentClassification.classification);

        this.setState(this.state);
        this.forceUpdate();
    }

    handleSubmit() {

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ loading: true });

        var method = this.state.currentClassification.classification.id > 0 ? 'UpdateClassification' : 'AddClassification';

        this.state.currentClassification.classification.customerId = this.props.customerId;

        var status = 0;

        fetch(window.$CustomerServiceURL + '/api/classification/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(this.state.currentClassification.classification),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                status = res.status;

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (status) {

                    case 200:
                        var classification = JSON.parse(JSON.stringify(this.state.currentClassification.classification));

                        reportDailyLog.action = 3; // update customer
                        reportDailyLog.objectAfter = JSON.stringify(classification);
                        submitReportDailyLog(reportDailyLog);

                        classification.id = res.data.id;

                        if (this.state.currentClassification.rowIndex < 0) {

                            this.state.classificationList.push(classification);

                        } else {

                            this.state.classificationList[this.state.currentClassification.rowIndex] = classification;
                        }

                        this.resetClassification();

                        this.setState({ loading: false });

                        this.forceUpdate();
                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });
    }

    deleteClassification(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        let classification = JSON.parse(JSON.stringify(this.state.classificationList[rowIndex]));

                        let classificationId = classification.id;

                        this.handleDeleteClassification(classificationId, rowIndex);
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerClassificationDeleteTitle'),
            title: translate('customer.customerClassificationDeleteQuestion')
        });

    }

    handleDeleteClassification(classificationId, rowIndex) {

        let reportDailyLog = this.state.reportDailyLog;
        var status = 0;

        this.setState({ loading: true });


        fetch(window.$CustomerServiceURL + '/api/classification/DeleteClassification/' + classificationId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {
                status = res.status;
                this.setState({ loading: false });

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {
                switch (status) {

                    case 200:
                        let classification = JSON.parse(JSON.stringify(this.state.classificationList[rowIndex]));

                        var items = this.state.classificationList;
                        items.splice(rowIndex, 1);
                        this.state.classificationList = items;

                        reportDailyLog.action = 3; // updatecustomer
                        reportDailyLog.objectAfter = JSON.stringify(classification);
                        submitReportDailyLog(reportDailyLog);

                        this.forceUpdate();

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }
            });

    }

    setValidators() {

        this.classificationValidator = new SimpleReactValidator({

            profit: {

                rule: value => {

                    let customerProfitPercentage = parseFloat(this.state.currentClassification.classification.customerProfitPercentage);
                    let portalProfitPercentage = parseFloat(this.state.currentClassification.classification.portalProfitPercentage);

                    return (customerProfitPercentage + portalProfitPercentage) == 100;
                }
            },

            greaterThanZero: {

                rule: value => {

                    return parseFloat(value) > 0;
                }
            },

            endRateGreaterThanStartRate: {

                rule: value => {

                    let startRate = parseFloat(this.state.currentClassification.classification.startRate);
                    let endRate = parseFloat(this.state.currentClassification.classification.endRate);

                    return endRate >= startRate;
                }
            }
        });
    }

    render() {
        return (
            <div>
                {
                    (session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                    <div>
                        <div>
                            <div className="m-b-40">
                                <small>{translate('customer.customerClassificationsTitleDescription')}</small>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-4">
                                <div className="input-group">
                                    <label className="validationRequired" htmlFor="txtClassificationName">{translate('customer.customerClassificationName')}</label>

                                    <div className="form-line">
                                        <input id="txtClassificationName" className="form-control js-customer-classification-change" name="currentClassification.classification.name" placeholder={translate('customer.customerClassificationNamePlaceHolder')} type="text" />
                                    </div>

                                    {this.classificationValidator.message('currentClassification.classification.name', this.state.currentClassification.classification.name, 'required', false, { default: translate('customer.customerClassificationNameRequired') })}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label htmlFor="txtClassificationStartRate">{translate('customer.customerClassificationStartRate')}</label>

                                    <div className="form-line">
                                        <input id="txtClassificationStartRate" className="form-control js-customer-classification-change js-decimal" name="currentClassification.classification.startRate" placeholder={translate('customer.customerClassificationStartRatePlaceHolder')} type="text" />
                                    </div>

                                    {this.classificationValidator.message('currentClassification.classification.startRate', this.state.currentClassification.classification.startRate, 'required|greaterThanZero|endRateGreaterThanStartRate', false, { default: translate('customer.customerClassificationStartRateRequired'), greaterThanZero: translate('customer.customerClassificationRateGreaterThanZero'), endRateGreaterThanStartRate: translate('customer.customerClassificationEndRateGreaterThanStartRate') })}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <label htmlFor="txtClassificationEndRate">{translate('customer.customerClassificationEndRate')}</label>

                                    <div className="form-line">
                                        <input id="txtClassificationEndRate" className="form-control js-customer-classification-change js-decimal" name="currentClassification.classification.endRate" placeholder={translate('customer.customerClassificationEndRatePlaceHolder')} type="text" />
                                    </div>

                                    {this.classificationValidator.message('currentClassification.classification.endRate', this.state.currentClassification.classification.endRate, 'required|greaterThanZero|endRateGreaterThanStartRate', false, { default: translate('customer.customerClassificationEndRateRequired'), greaterThanZero: translate('customer.customerClassificationRateGreaterThanZero'), endRateGreaterThanStartRate: translate('customer.customerClassificationEndRateGreaterThanStartRate') })}
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">

                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="txtClassificationCustomerProfitPercentage">{translate('customer.customerClassificationCustomerProfitPercentage')}</label>

                                    <div className="form-line">
                                        <input id="txtClassificationCustomerProfitPercentage" className="form-control js-customer-classification-change js-decimal" name="currentClassification.classification.customerProfitPercentage" placeholder={translate('customer.customerClassificationCustomerProfitPercentagePlaceHolder')} type="text" />
                                    </div>

                                    {this.classificationValidator.message('currentClassification.classification.customerProfitPercentage', this.state.currentClassification.classification.customerProfitPercentage, 'required|profit', false, { default: translate('customer.customerClassificationCustomerProfitPercentageRequired'), profit: translate('customer.customerClassificationProfitPercentage100') })}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="txtClassificationPortalProfitPercentage">{translate('customer.customerClassificationPortalProfitPercentage')}</label>

                                    <div className="form-line">
                                        <input id="txtClassificationPortalProfitPercentage" className="form-control js-customer-classification-change js-decimal" name="currentClassification.classification.portalProfitPercentage" placeholder={translate('customer.customerClassificationPortalProfitPercentagePlaceHolder')} type="text" />
                                    </div>

                                    {this.classificationValidator.message('currentClassification.classification.portalProfitPercentage', this.state.currentClassification.classification.portalProfitPercentage, 'required|profit', false, { default: translate('customer.customerClassificationPortalProfitPercentageRequired'), profit: translate('customer.customerClassificationProfitPercentage100') })}
                                </div>
                            </div>

                        </div>

                        <div className="row clearfix">
                            <div className="col-md-12">
                                <button type="button" className="btn m-r-10" onClick={this.resetClassification} style={{ display: this.state.currentClassification.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                    <i className="material-icons">&#xe5c4;</i>
                                    <span>{translate('forms.buttonCancel')}</span>
                                </button>

                                <button type="button" className="btn btn-primary" onClick={this.saveClassification}>
                                    <i className="material-icons">&#xe145;</i>
                                    <span>{translate(this.state.currentClassification.rowIndex < 0 ? 'customer.customerClassificationsAddNew' : 'customer.customerClassificationsUpdate')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                }

                <ReactTable
                    data={this.state.classificationList}
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "ID",
                                    accessor: "id",
                                    maxWidth: 60
                                },
                                {
                                    Header: translate('customer.customerClassificationName'),
                                    accessor: "name",
                                },
                                {
                                    Header: translate('customer.customerClassificationStartRate'),
                                    Cell: row => { return row.original.startRate + '%' }
                                },
                                {
                                    Header: translate('customer.customerClassificationEndRate'),
                                    Cell: row => { return row.original.endRate + '%' }
                                },
                                (session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                {
                                    Cell: row => (
                                        <div className="align-center">
                                            <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" disabled={this.state.currentClassification.rowIndex >= 0} onClick={() => { this.deleteClassification(row.index) }}>
                                                <i className="material-icons">&#xe872;</i>
                                            </button>

                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" disabled={this.state.currentClassification.rowIndex >= 0} onClick={() => { this.editClassification(row.index) }}>
                                                <i className="material-icons">&#xe3c9;</i>
                                            </button>
                                        </div>
                                    ),
                                    maxWidth: 150
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight m-b-40"
                    previousText={translate('forms.previousText')}
                    nextText={translate('forms.nextText')}
                    noDataText={translate('forms.noDataText')}
                    pageText={translate('forms.pageText')}
                    ofText={translate('forms.ofText')}
                    rowsText={translate('forms.rowsText')}
                />
            </div >
        )
    }
}